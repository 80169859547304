import * as React from "react";
import {Localization} from "../../modules/localization-module";
import {RespondentModel} from "../../models/profileCrud/RespondentModel";
import {SelectItemModel} from "../../models/SelectItemModel";
import {RespondentCrudHelper} from "../../models/profileCrud/RespondentCrudHelper";
import {Select2Component} from "../ui-components/Select2Component";
import {SubToolEnum} from "../../models/enums/subToolEnum";
import {RespondentExistingModel} from "../../models/profileCrud/RespondentExistingModel";
import {SelectedTabEnum} from "../../models/enums/respondentSelectedTabEnum";
import {RespondentDataComponent} from "./RespondentDataComponent";

interface IRespondentComponentProps {
    setValue: (key: string, val: string | string[]) => void;
    setRespondentType: (value: number) => void;
    setDistributionValues: (key: string, val: string | string[]) => void;
    respondentTypeEnumModels?: SelectItemModel[];
    respondent: RespondentModel;
    subtool?: SubToolEnum;
    existingRespondentsModel?: SelectItemModel[];
    existingRespondents: RespondentExistingModel[];
    setRespondentId?: (value: string) => void
    setSelectedTab: (selected: SelectedTabEnum) => void;
}

export class RespondentComponent extends React.Component<IRespondentComponentProps> {

    private readonly respondentCrudHelper: RespondentCrudHelper;

    constructor(props: IRespondentComponentProps) {
        super(props);

        this.respondentCrudHelper = new RespondentCrudHelper();
    }

    private setExisting(respondentId: string) {

        if (respondentId){
            const existingRespondent = this.props.existingRespondents.find(x => x.id == respondentId);
            this.props.setRespondentId(existingRespondent.id);
            this.setRespondentValues(existingRespondent);
        }
        else{
            this.props.setRespondentId(null);
            this.setRespondentValues(null);
        }
    }

    private setRespondentValues(respondent: RespondentExistingModel) {
        this.props.setValue("name", respondent?.name);
        this.props.setValue("email", respondent?.email);
        this.props.setDistributionValues("email", respondent?.email);
    }

    private setNewRespondentTab() {
        this.props.setSelectedTab(SelectedTabEnum.newRespondent);
        this.setExisting(null);
    }

    private getExistingRespondentAsSelect() : SelectItemModel[] {
        const optionalItem = new SelectItemModel();
        const existing = this.props.existingRespondents.map(r => {
            const selectItemModel: SelectItemModel = {
                value: r.id,
                label: r.name,
                secondaryLabel: r.email,
                selected: false
            };

            return selectItemModel;
        });

        return [optionalItem].concat(existing);
    }

    render() {

        return (
            <>
                <div className="row">
                    <div className="col-12">
                        {this.props.subtool != null &&
                        <RespondentDataComponent
                            setDistributionValues={(k, v) => this.props.setDistributionValues(k, v)}
                            setValue={(k, v) => this.props.setValue(k, v)}
                            setRespondentType={(value) => this.props.setRespondentType(value)}
                            respondentTypeEnumModels={this.props.respondentTypeEnumModels}
                            respondent={this.props.respondent}
                            subtool={this.props.subtool}
                        />
                        }

                        {this.props.subtool == null &&
                        <>
                            <ul className="nav nav-tabs nav-justified">
                                <li className="nav-item">
                                    <a className="nav-link active"
                                        onClick={() => this.setNewRespondentTab()}
                                       id="select-new-respondent" href={"#new-respondent-tab"}
                                       aria-controls="new-respondent-tab" aria-expanded="true" role="tab"
                                       data-toggle="tab">
                                        {Localization.getText("Respondent_NewRespondent")}
                                    </a>
                                </li>
                                <li className="nav-item active">
                                    <a className="nav-link"
                                       onClick={() => this.props.setSelectedTab(SelectedTabEnum.existingRespondent)}
                                       id="select-person-respondent" href={"#existing-respondent-tab"}
                                       aria-controls="existing-respondent-tab" aria-expanded="false" role="tab"
                                       data-toggle="tab">
                                        {Localization.getText("Respondent_ExistingRespondent")}
                                    </a>
                                </li>
                            </ul>

                            <div className="tab-content">
                                <div role="tabpanel" className="tab-pane active" id="new-respondent-tab">
                                    <RespondentDataComponent
                                        setDistributionValues={(k, v) => this.props.setDistributionValues(k, v)}
                                        setValue={(k, v) => this.props.setValue(k, v)}
                                        setRespondentType={(value) => this.props.setRespondentType(value)}
                                        respondentTypeEnumModels={this.props.respondentTypeEnumModels}
                                        respondent={this.props.respondent}
                                        subtool={this.props.subtool}
                                    />
                                </div>

                                <div role="tabpanel" className="tab-pane" id="existing-respondent-tab">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="form-group">
                                                <label htmlFor="ExistingRespondent">
                                                    {Localization.getText("Respondent_FindExistingRespondent")}
                                                </label>
                                                <Select2Component multiple={false}
                                                                  options={this.getExistingRespondentAsSelect()}
                                                                  select2Id="respondent-type-select"
                                                                  allowClear={true}
                                                                  itemSelected={(value) => this.setExisting(value)}
                                                                  itemUnselected={() => this.setExisting(null)}
                                                                  useTemplate={true}
                                                                  allowSecondaryValueSearch={true}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                        }
                    </div>
                </div>
            </>);
    }
}
