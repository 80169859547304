import { useState, useEffect } from 'react';
import {GarudaApi} from "../../infrastructure/garudaApi";

interface UseLatestRespondentCreatedHookProps<T> {
    setValue: (key: string, value: T) => void;
    getValue: (key: string, dist: T, mapper: (src: T, dist: T) => T) => T | null;
    removeStorage: (key: string) => void;
}

function useLatestRespondentCreatedHook<T>(): UseLatestRespondentCreatedHookProps<T> {
    const getLocalstorageValue = (key: string, dist: T, mapper: (src: T, dist: T) => T): T | null => {
        const objectString = localStorage.getItem(key);
        if (!objectString) {
            return dist;
        }
        const obj = JSON.parse(objectString) as T;
        return mapper(obj, dist);
    };

    const setStorage = (key: string, value: T): void => {
        const valueString = JSON.stringify(value);
        localStorage.setItem(key, valueString);
    };

    const removeStorage = (key: string): void => {
        localStorage.removeItem(key);
    }

    return { getValue: getLocalstorageValue, setValue: setStorage, removeStorage: removeStorage };
}

export default useLatestRespondentCreatedHook;

