import * as React from "react";
import { Localization } from "../../modules/localization-module";
import { TooltipModule } from "ditmer-embla";
import { wizardStepAnalysis, wizardStepDistribution } from "../../models/constants/wizardConstants";
import {SpinnerComponent} from "../ui-components/SpinnerComponent";

interface IWizardComponent {
    content: (step: number) => JSX.Element;
    contentValid: (element: JSX.Element) => string;
    saveRespondentClick: () => any;
    distributeRespondentClick: () => void;
    headers: string[];
    currentStep?: number;
}

interface IWizardState {
    currentStep: number;
    didSaveRespondent: boolean;
    loadingText: string;
}

export class WizardComponent extends React.Component<IWizardComponent, IWizardState> {

    constructor(props: IWizardComponent) {
        super(props);

        this.state = {
            currentStep: this.props.currentStep ? this.props.currentStep : 1,
            didSaveRespondent: false,
            loadingText: ""
        };
    }

    private next() {
        this.setState({
            currentStep: this.state.currentStep + 1
        });
    }

    private prev() {
        this.setState({
            currentStep: this.state.currentStep - 1
        });
    }

    private renderStepState(step: number) {
        if (step === this.state.currentStep || step > this.state.currentStep) {
            return (<div>{step}</div>);
        } else {
            return (<svg className="embla-icon" aria-hidden="true" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <use href="/dist/icons/sprite.symbol.svg#success" />
            </svg>);
        }
    }

    private getStepClass(step: number): string {
        if (step === this.state.currentStep) {
            return "active-step";
        } else if (step < this.state.currentStep) {
            return "done-step";
        }

        return "";
    }

    private saveRespondentClick() {
        this.setState({
            loadingText: Localization.getText("PersonalProfile_Respondent_Creating")
        });

        this.props.saveRespondentClick().then(() => {

            this.setState({
                didSaveRespondent: true,
                loadingText: ""
            });

            this.next();
        });
    }


    private distributeRespondentClick() {
        this.props.distributeRespondentClick();

        this.setState({
            loadingText: Localization.getText("PersonalProfile_Distribution_Sending")
        });
    }

    private renderNextButton(content: JSX.Element) {
        const error = this.props.contentValid(content);
        const disabledClass = !error ? "" : "disabled-button";
        let button: JSX.Element;

        if (this.state.currentStep === wizardStepAnalysis && this.state.didSaveRespondent === false) {
            button = (<button type="button"
                              className={`btn btn-primary ${disabledClass}`}
                              onClick={() => this.saveRespondentClick()}>
                {Localization.getText("PersonalProfile_Respondent_SaveAndDistribute")}
            </button>);
        }
        else if (this.state.currentStep === wizardStepDistribution) {
            button = (<button type={"button"}
                              className={`btn btn-primary ${disabledClass}`}
                              onClick={() => this.distributeRespondentClick()}
                >
                {Localization.getText("PersonalProfile_Respondent_Distribute")}
            </button>);
        }
        else {
            button = (<button type="button"
                              className={`btn btn-primary ${disabledClass}`}
                              onClick={() => {
                                  if(!error){
                                      return this.next()
                                  }
                                  return null;
                              }}>
                {Localization.getText("Global_Next")}
            </button>);
        }

        if (error) {
            return (<span data-tooltip="white" data-placement="top" data-html="true" title={error} data-original-title={error}>
                        {button}
                    </span>
            );
        } else {
            return button;
        }
    }

    componentDidUpdate() {
        new TooltipModule($("[data-tooltip]"));
    }

    render() {
        const content = this.props.content(this.state.currentStep);

        return (
            <>
                <div className="wizard-panel">

                    <div className="wizard-steps">
                        <div className={`wizard-step ${this.getStepClass(1)}`}>
                            <div className="wizard-step-content">
                                {this.renderStepState(1)}
                            </div>
                            <div className="wizard-step-text">{this.props.headers[0]}</div>
                        </div>

                        <div className={`wizard-step ${this.getStepClass(2)}`}>
                            <div className="wizard-step-content">
                                {this.renderStepState(2)}
                            </div>
                            <div className="wizard-step-text">{this.props.headers[1]}</div>
                        </div>

                        <div className={`wizard-step ${this.getStepClass(3)}`}>
                            <div className="wizard-step-content">
                                {this.renderStepState(3)}
                            </div>
                            <div className="wizard-step-text">{this.props.headers[2]}</div>
                        </div>
                    </div>

                    <div className="wizard-content">

                        <h3 className="wizard-content-header bold">{this.props.headers[this.state.currentStep - 1]}</h3>
                        {content}

                        <div className="wizard-actions">
                            {this.state.currentStep === 2 &&
                            <div className="wizard-actions-left">
                                <button type="button" className="btn btn-default" onClick={() => this.prev()}>{Localization.getText("Global_Previous")}</button>
                            </div>
                            }

                            <div className="wizard-actions-right">
                                {
                                    !this.state.loadingText ? this.renderNextButton(content) : <><span className="margin-right-s">{this.state.loadingText}</span><SpinnerComponent small={true}/></>
                                }
                            </div>
                        </div>

                    </div>
                </div>
            </>);
    }
}
