import * as React from "react";
import {Localization} from "../../modules/localization-module";
import {RespondentModel} from "../../models/profileCrud/RespondentModel";
import {TagModuleComponent} from "../../modules/tag-module";
import {SelectItemModel} from "../../models/SelectItemModel";
import {RespondentCrudHelper} from "../../models/profileCrud/RespondentCrudHelper";
import {Select2Component} from "../ui-components/Select2Component";
import {SubToolEnum} from "../../models/enums/subToolEnum";
import {DatePickerComponent} from "../ui-components/DatePickerComponent";
import {DatetimePickerTypeEnum} from "ditmer-embla";

interface IRespondentDataComponentProps {
    setValue: (key: string, val: string | string[]) => void;
    setRespondentType: (value: number) => void;
    setDistributionValues: (key: string, val: string | string[]) => void;
    respondentTypeEnumModels?: SelectItemModel[];
    respondent: RespondentModel;
    subtool?: SubToolEnum;
}

export class RespondentDataComponent extends React.Component<IRespondentDataComponentProps> {

    private readonly respondentCrudHelper: RespondentCrudHelper;

    constructor(props: IRespondentDataComponentProps) {
        super(props);

        this.respondentCrudHelper = new RespondentCrudHelper();
    }

    private setValue(key: string, val: string | string[]) {
        this.props.setValue(key, val);
    }

    private setEmail(value: string) {
        this.props.setValue("email", value);
        this.props.setDistributionValues("email", value);
    }

    private onAddItems(selectedTags: string[]) {
        this.setValue("selectedTags", selectedTags);
    }

    private validateCountryCode(keyDownEvent: any) {
        if (!parseInt(keyDownEvent.key) && keyDownEvent.keyCode !== 8 && keyDownEvent.keyCode !== 46 && keyDownEvent.keyCode !== 9) {
            keyDownEvent.preventDefault();
        }
    }

    render() {

        return (
            <>

                <div className="row">
                    <div className="col-sm-12">
                        <div className="form-group">
                            <label htmlFor="Name">
                                {Localization.getText("PersonalProfile_Respondent_Name")}
                                <span className="required-field">∗</span>
                            </label>
                            <input name="Name"
                                   className="form-control"
                                   onChange={(e) => this.setValue("name", e.target.value)}
                                   defaultValue={this.props.respondent.name}
                                   placeholder={Localization.getText("PersonalProfile_Respondent_Name_Placeholder")}
                                   maxLength={90}/>
                        </div>
                    </div>
                    <div className="col-sm-12">
                        <div className="form-group">
                            <label htmlFor={"Email"}>
                                {Localization.getText("GENERAL_EMAIL")}
                                <span className="required-field">∗</span>
                            </label>
                            <form>
                                <input name="Email" className="form-control"
                                       onChange={(e) => this.setEmail(e.target.value)}
                                       defaultValue={this.props.respondent.email}
                                       placeholder={Localization.getText("PersonalProfile_Respondent_Email_Placeholder")}/>
                            </form>
                        </div>
                    </div>
                </div>

                <div className="row margin-top-s">
                    <div className="col-3">
                        <div className="form-group">
                            <label
                                htmlFor={"CountryCode"}>{Localization.getText("PersonalProfile_Respondent_CountryCode")}</label>
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <div className="input-group-text">+</div>
                                </div>
                                <input name="CountryCode"
                                       type="number"
                                       className="form-control"
                                       id="iconLeftInput"
                                       onKeyDown={(e) => this.validateCountryCode(e)}
                                       onChange={(e) => this.setValue("countryCode", e.target.value)}
                                       defaultValue={this.props.respondent.countryCode}
                                       placeholder={Localization.getText("PersonalProfile_Respondent_CountryCode_Placeholder")}/>
                            </div>
                        </div>
                    </div>

                    <div className="col-9">
                        <div className="form-group">
                            <label
                                htmlFor="Phone">{Localization.getText("PersonalProfile_Respondent_Phone")}</label>
                            <input name="Phone" type="text" className="form-control"
                                   onChange={(e) => this.setValue("phone", e.target.value)}
                                   defaultValue={this.props.respondent.phone}
                                   placeholder={Localization.getText("PersonalProfile_Respondent_Phone_Placeholder")}/>
                        </div>
                    </div>
                </div>

                <div className="row margin-top-m">

                    {this.props.respondentTypeEnumModels != null &&
                    this.props.subtool != null &&
                    this.respondentCrudHelper.canSetRespondentType(this.props.subtool) &&
                    <div className="col-sm-4">
                        <div className="form-group">
                            <label htmlFor="Tags">{Localization.getText("Respondent_Type")}</label>
                            <Select2Component multiple={false}
                                              options={this.props.respondentTypeEnumModels}
                                              select2Id="respondent-type-select"
                                              itemSelected={(value) => this.props.setRespondentType(parseInt(value))}
                                              itemUnselected={() => null}
                                              additionalSelect2Options={{"tags": true}}
                            />
                        </div>
                    </div>
                    }

                    {this.props.subtool != null &&
                    this.respondentCrudHelper.canSetExpiryDate(this.props.subtool) &&
                    <div className="col-sm-8">
                        <DatePickerComponent
                            calenderType={DatetimePickerTypeEnum.Date}
                            label={Localization.getText("Global_Expiration")}
                            disabled={false}
                            dateSelected={(key, value) => this.props.setValue("ExpiryDate", value)}
                            settingkey={"expiry-date"}
                            value={this.props.respondent.expiryDate}
                            allowClear={false}/>
                    </div>
                    }

                    <div className="col-sm-12 margin-top-m">
                        <div className="form-group">
                            <label htmlFor="Tags">{Localization.getText("Respondent_Tags")}</label>
                            <TagModuleComponent
                                select2Id={"respondent-tags-select2"}
                                options={this.props.respondent.tags}
                                addTags={(selectedTags) => this.onAddItems(selectedTags)}
                                removeTags={() => null}/>
                        </div>
                    </div>
                </div>
            </>);
    }
}
