import * as React from "react";
import {Localization} from "../../modules/localization-module";

type TagItemProps = {
    tag: string;
    className: string;
    menu: boolean;
    addTag: (item: string) => void;
    removeTag: (item: string) => void;
};

export const TagItem = (props: TagItemProps) => {
    return (
        <>

            {!props.menu &&
            <div className={props.className}
                 onClick={() => props.removeTag(props.tag)}>
                {`${props.tag}`}
                <svg className="embla-icon" aria-hidden="true" version="1.1" xmlns="http://www.w3.org/2000/svg">
                    <use href="/dist/icons/sprite.symbol.svg#remove"/>
                </svg>
            </div>
            }

            {props.menu &&
            <div className={`btn-group ${props.className}`}>
                <div className="flex-v-align-items" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    {`${props.tag}`}
                    <svg className="embla-icon" aria-hidden="true" version="1.1" xmlns="http://www.w3.org/2000/svg">
                        <use href="/dist/icons/sprite.symbol.svg#arrow-down" />
                    </svg>
                </div>
                <ul className="dropdown-menu">
                    <li>
                        <a onClick={() => props.addTag(props.tag)}>
                            {Localization.getText("Add_Tags_To_All")}
                        </a>
                    </li>
                    <li>
                        <a onClick={() => props.removeTag(props.tag)}>
                            {Localization.getText("Global_Remove")}
                        </a>
                    </li>
                </ul>
            </div>
            }

        </>
    )
};
