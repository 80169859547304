import * as React from "react";
import { GarudaApi } from "../../infrastructure/garudaApi";
import { Localization } from "../../modules/localization-module";
import { EmblaIcon } from "../ui-components/EmblaIcon";
import { useEffect, useRef, useState } from "react";
import { Label } from "../ui-components/LabelComponent";
import { Select2Component } from "../ui-components/Select2Component";
import { SelectItemModel } from "../../models/SelectItemModel";
import { TagEnum } from "../../models/enums/TagEnum";
import { BadgePill } from "../ui-components/BadgePill";
import { GroupProfileTypeEnum } from "../../models/enums/groupProfileTypeEnum";
import { ProfileIcon } from "./ProfileIcon";
import { TagIcon } from "./TagIcon";
import { ProfileCount } from "./ProfileCount";
import { GroupProfileModel } from "../../models/profileCrud/GroupProfileModel";
import { SpinnerComponent } from "../ui-components/SpinnerComponent";
import {FocusProfileModel} from "../../models/FocusProfileModel";

type GroupProfile = {
    key: string;
    value: string;
    profiles?: FocusProfileModel[];
    isTag: boolean;
}

export interface IGroupProfileComponentProps {
    saveCallback: (parameter?: any) => void;
    saveButtonSelector: JQuery<HTMLElement> | string;
    profileId?: string;
    respondentId?: string;
    type?: GroupProfileTypeEnum;
    name?: string;
    groupTags?: string[];
    selectedProfileTags?: string[];
    selectedProfiles?: string[];
    modalSelector?: JQuery<HTMLElement>;
}

export function GroupProfileComponent(props: IGroupProfileComponentProps) {
    const [name, setName] = useState<string>(props.name);
    const [profileTags, setAllProfileTags] = useState<SelectItemModel[]>([]);
    const [respondentTags, setAllRespondentTags] = useState<SelectItemModel[]>([]);
    const [allProfiles, setAllProfiles] = useState<SelectItemModel[]>([]);
    const [profilesLoading, setProfilesLoading] = useState<boolean>(false);
    const appRef = useRef<HTMLDivElement>(null);

    // group metadata tags
    const [chosenGroupTags, setChosenGroupTags] = useState<string[]>(props.groupTags ?? []);

    // group tags/profiles
    const [chosenProfiles, setChosenProfiles] = useState<GroupProfile[]>([]);

    const garudaApi = new GarudaApi();

    useEffect(() => {
        garudaApi.getCompanyTags(TagEnum.ProfileTag).then(tags => {
            const optionalItem = new SelectItemModel();
            const profileTags = [optionalItem].concat(tags.map(t => ({...t, secondaryLabel: Localization.getText("General_Tag")})));
            setAllProfileTags(profileTags);
        });
        garudaApi.getCompanyTags(TagEnum.RespondentTag).then(tags => {
            setAllRespondentTags(tags.map(t => ({...t, secondaryLabel: Localization.getText("General_Tag")})));
        });
    }, [])

    useEffect(() => {
        if(props.type === GroupProfileTypeEnum.Statisk) {
            setProfilesLoading(true);
            garudaApi.getFocusProfiles().then(profiles => {
                const items = profiles
                    .map(p => ({ label: p.name, value: p.id, selected: false, secondaryLabel: Localization.getText("Global_Profil") }))
                    .sort((p1, p2) => p1.label.localeCompare(p2.label));
                setAllProfiles(items);
                setProfilesLoading(false);
            })
        }
    }, [props.type]);

    useEffect(() => {

        if (typeof props.saveButtonSelector != "string") {
            props.saveButtonSelector.off("click").on("click", saveGroup);
        }
        else if(props.modalSelector) {
            props.modalSelector.find(props.saveButtonSelector).off("click").on("click",
                () => {
                    saveGroup();
                });
        }
    }, [name, chosenGroupTags, chosenProfiles])

    useEffect(() => {
        if(!props.selectedProfileTags) return;

        const newProfiles = chosenProfiles.slice();
        const allTags = respondentTags.concat(profileTags);

        props.selectedProfileTags.forEach(profileTag => {
            const selectedTag = allTags.find(x => x.value === profileTag);
            if(!selectedTag) return;

            if(newProfiles.map(p => p.key).includes(profileTag)) return;

            const tag: GroupProfile = ({ key: selectedTag.value, value: selectedTag.label, isTag: true });
            newProfiles.push(tag);
        })

        setChosenProfiles(newProfiles);
    }, [props.selectedProfileTags, profileTags, respondentTags])

    useEffect(() => {
        const tagResults: (Promise<{tag: string, profiles: FocusProfileModel[]}>)[] = [];
        const newProfiles = chosenProfiles.slice();

        newProfiles.forEach(profile => {
            if(!profile.isTag || profile.profiles) return;

            tagResults.push(garudaApi.getProfilesForTag(profile.key));
        })

        Promise.all(tagResults).then(results => {
            const newList = newProfiles.map(item => {
                const tagResult = results.find(r => r.tag === item.key);
                if(tagResult) {
                    return { ...item, profiles: tagResult.profiles };
                }
                return item;
            })

            setChosenProfiles(newList);
        });
    }, [chosenProfiles.length])

    useEffect(() => {
        if(props.type !== GroupProfileTypeEnum.Statisk || !props.selectedProfiles || !allProfiles) return;

        const newProfiles = chosenProfiles.slice();

        props.selectedProfiles.forEach(profileId => {
            const selectedProfile = allProfiles.find(x => x.value === profileId);
            if(!selectedProfile || newProfiles.map(p => p.key).includes(profileId)) return;

            newProfiles.push(({ key: selectedProfile.value, value: selectedProfile.label, isTag: false, profiles: [] }));
        })

        setChosenProfiles(newProfiles);
    }, [props.selectedProfiles, allProfiles])

    const groupTagSelected = (tag: string) => {
        const newTags = chosenGroupTags.slice();
        newTags.push(tag);
        setChosenGroupTags(newTags);
    }

    const groupTagRemoved = (tag: string) => {
        const newTags = chosenGroupTags.slice();
        setChosenGroupTags(newTags.filter(groupTag => groupTag !== tag));
    }

    const profileOrTagSelected = (id: string) => {
        const newProfiles = chosenProfiles.slice();
        const allTags = respondentTags.concat(profileTags);
        const selectedTag = allTags.find(x => x.value === id);

        if(selectedTag) {
            const tag: GroupProfile = ({ key: selectedTag.value, value: selectedTag.value, isTag: true });
            newProfiles.push(tag);
        } else {
            const selectedProfile = allProfiles.find(x => x.value === id);
            newProfiles.push(({ key: selectedProfile.value, value: selectedProfile.label, isTag: false, profiles: [] }));
        }

        setChosenProfiles(newProfiles);
    }

    const profileOrTagRemoved = (key: string) => {
        const newProfiles = chosenProfiles.slice();
        setChosenProfiles(newProfiles.filter(profile => profile.key !== key));
    }

    const saveGroup = async () => {
        const model: GroupProfileModel = {
            respondentId: props.respondentId,
            profileId: props.profileId,
            name: name,
            type: props.type,
            selectedProfileTags: chosenGroupTags,
            selectedTags: chosenProfiles.filter(p => p.isTag).map(p => p.key),
            selectedProfiles: chosenProfiles.filter(p => !p.isTag).map(p => p.key)
        }

        let profileId: string;
        if(props.profileId) {
            await garudaApi.updateGroupProfile(model);
        } else {
            profileId = await garudaApi.createGroupProfile(model);
        }

        props.saveCallback(profileId);
    }

    const renderGroupMetadata = () =>
        <div id="group-profile-app" data-issubmitting="false" ref={appRef}>
            <div className="row margin-bottom-s">
                <div className="col-md-12">
                    <h4>{Localization.getText("GENERAL_GROUP")}</h4>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="form-group">
                        <Label text={Localization.getText("UserProfile_User_Name")} htmlFor={"name"} isRequired={true} />
                        <input
                            type={"text"}
                            className={"form-control"}
                            name={"name"}
                            defaultValue={name}
                            onBlur={(e: React.FocusEvent<HTMLInputElement>) => setName(e.target.value)}
                            placeholder={Localization.getText("UserProfile_User_Name_Placeholder")}
                        />
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="form-group">
                        <Label text={Localization.getText("General_GroupProfile_Tags")} htmlFor={"tagsForGroup"} />
                        <Select2Component
                            key={chosenGroupTags.join()}
                            multiple={false}
                            options={profileTags.filter(p => !chosenGroupTags.includes(p.value))}
                            select2Id="groupprofile-tags-select"
                            itemSelected={groupTagSelected}
                            itemUnselected={() => null}
                            allowClear={true}
                            clearOnSelect={true}
                            additionalSelect2Options={{"tags": true}}
                        />
                    </div>
                </div>
                <div className="col-md-12 margin-top-s">
                    {chosenGroupTags.map(tagName =>
                        <BadgePill iconName="close" onClick={() => groupTagRemoved(tagName)} key={tagName} classes="groupprofile-tag margin-right-xs margin-bottom-xs">{tagName}</BadgePill>
                    )}
                </div>
            </div>
        </div>

    const renderProfileHeader = () => {
        if(props.type === GroupProfileTypeEnum.Dynamisk) return <h4>{Localization.getText("Global_Profiles")}</h4>;

        const profileCount = chosenProfiles.filter(p => p.profiles).map(p => p.isTag ? p.profiles.length : 1).reduce((a, b) => a + b, 0);
        const headerText = profileCount === 1 ? Localization.getText("Global_Profil") : Localization.getText("Global_Profiles");
        return <h4>{profileCount} {headerText}</h4>;
    }

    const renderChosenProfiles = () => {
        const profiles = chosenProfiles.filter(p => !p.isTag);
        return <>
            {profiles.map(profile =>
                <div className="row margin-bottom-s" key={profile.key}>
                    <div className="col-md-12 d-flex justify-content-between">
                        <div>
                            <ProfileIcon />
                            <span className="margin-left-s">{profile.value}</span>
                        </div>
                        <div className="remove-profile-icon" onClick={() => profileOrTagRemoved(profile.key)}>
                            <EmblaIcon iconName="close" />
                        </div>
                    </div>
                </div>
            )}
        </>
    }

    const renderChosenTags = () => {
        const chosenTags = chosenProfiles.filter(p => p.isTag);
        if(chosenTags.length === 0) return;

        return <>
            <h5 className="margin-bottom-s">{Localization.getText("General_Tags")}</h5>
            {chosenTags.map(tag =>
                <div className="row margin-bottom-s" key={tag.key}>
                    <div className="col-md-12 d-flex justify-content-between">
                        <div className="">
                            <TagIcon />
                            <span className="margin-left-s">{tag.value} <ProfileCount profiles={tag.profiles} classes="margin-left-s" /></span>
                        </div>
                        <div className="remove-profile-icon" onClick={() => profileOrTagRemoved(tag.key)}>
                            <EmblaIcon iconName="close" />
                        </div>
                    </div>
                </div>
            )}
        </>
    }

    const renderProfileAndTagDropdown = () => {
        return <div className="form-group">
            <Label text={Localization.getText("General_Choose_Profiles")} htmlFor={"profilesForGroup"} />
            <Select2Component
                key={getAvailableProfilesAndTags(chosenProfiles, respondentTags, profileTags, allProfiles).map(x => x.value).join()}
                multiple={false}
                options={getAvailableProfilesAndTags(chosenProfiles, respondentTags, profileTags, allProfiles)}
                select2Id="groupprofile-profiles-select"
                itemSelected={profileOrTagSelected}
                itemUnselected={() => {}}
                allowClear={true}
                clearOnSelect={true}
                useTemplate={true}
            />
        </div>
    }

    return (
        <>
            {renderGroupMetadata()}
            <hr />
            {profilesLoading ? <SpinnerComponent /> : <>
                <div className="row margin-bottom-s">
                    <div className="col-md-12">
                        {renderProfileHeader()}
                    </div>
                </div>
                <div className="row margin-bottom-m">
                    <div className="col-md-12">
                        {renderProfileAndTagDropdown()}
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        {renderChosenProfiles()}
                        {renderChosenTags()}
                    </div>
                </div>
            </>}
        </>
    );
}

const getAvailableProfilesAndTags = (chosenProfiles: GroupProfile[], respondentTags: SelectItemModel[], profileTags: SelectItemModel[], profiles: SelectItemModel[]) => {
    const allItems = profiles.concat(profileTags).concat(respondentTags);
    const chosenKeys = chosenProfiles.map(p => p.key);
    const placeholderListItem = new SelectItemModel();

    return [placeholderListItem].concat(allItems.filter(item => !chosenKeys.includes(item.value)));
}
