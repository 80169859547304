
export class NewUsersSharingModel {

    constructor(profileIds: string[]) {
        this.profileIds = profileIds;
        this.newUserSharingModels = []
    }

    profileIds: string[]
    newUserSharingModels: NewUserSharingModel[];
}

export class NewUserSharingModel {

    constructor(email: string, name: string, role: string) {
        this.email = email;
        this.name = name;
        this.role = role;
    }

    email: string;
    name: string;
    role: string;
    timeLimit: string;
}
