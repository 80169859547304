export class StateStorage {

    public save(storeKey: string, payload: any): void {

        const serializedPayload = JSON.stringify(payload);
        sessionStorage.setItem(storeKey, serializedPayload);
    }

    public load<T>(storeKey: string): T {

        const serializedData = sessionStorage.getItem(storeKey);
        return JSON.parse(serializedData) as T;
    }

    public clear(storeKey: string): void {
        sessionStorage.removeItem(storeKey);
    }

    public clearAll(): void {
        sessionStorage.clear();
    }

    public removeItem(itemToRemove: string, storeKey: string) {
        const serializedData = sessionStorage.getItem(storeKey);
        const parsedData = JSON.parse(serializedData) as any[];

        const newarray = parsedData.filter((entry: any) => entry.profileId !== itemToRemove);
        this.save(storeKey, newarray);
    }
}
