import { DateSettingModel } from "../settings/DateSettingModel";
import { KeyValueSettingModel } from "../settings/KeyValueSettingModel";
import {SelectItemModel} from "../SelectItemModel";

export class ToolCrudModel {
    tools: ToolModel[];
    commonSettings: CommonSettings;
    respondentTypeEnumModels: SelectItemModel[];

    public AllQuestionarySettings() {
        const allQuestionarySettings = this.tools.selectMany(t => t.analysis).selectMany(aa => aa.questionnaireSettings)
        return allQuestionarySettings.distinct((a, b) => a.key === b.key);
    }

    private SelectedTool(toolId: string) {
        return this.tools.find(t => t.toolId === toolId);
    }

    public SelectedSubTool(toolId: string, subtoolId: string) {
        const selectedTool = this.SelectedTool(toolId);
        return selectedTool?.analysis.find(t => t.toolSubId === subtoolId);
    }
}

export interface Language {
    languageId: string;
    name: string;
    nameENU: string;
}

export interface CheckSettingModel {
    userHasLicense: boolean;
    label: string;
    value: boolean;
    key: string;
    validate: boolean;
}

export interface Analysis {
    toolSubId: string;
    order: number;
    name: string;
    language: string;
    availableLanguages: Language[];
    purposeSettings: KeyValueSettingModel[];
    questionnaireSettings: CheckSettingModel[];
    specialSetting: KeyValueSettingModel[];
}

export interface ToolModel {
    toolId: string;
    toolSpecial: string;
    nameShort: string;
    name: string;
    lng: string;
    analysis: Analysis[];
}

export interface CommonSettings {
    checkDates: DateSettingModel[];
    checkLabels: CheckSettingModel[];
}
