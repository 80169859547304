import * as React from "react";
import { GarudaApi } from "../../../infrastructure/garudaApi";
import { DropdownModule } from "ditmer-embla";
import { Localization } from "../../../modules/localization-module";
import { LanguageDa } from "../../../infrastructure/constants";
import { RespondentModel } from "../../../models/profileCrud/RespondentModel";
import { Language, ToolCrudModel } from "../../../models/profileCrud/ToolCrudModel";
import {SelectListItem} from "../../ui-components/SelectListItem";

interface IProfileLanguageProps {
    respondent: RespondentModel;
    selectLanguage: (purpose: string) => void;
    toolData: ToolCrudModel;
    dropdown: boolean;
}

export class ProfileLanguageComponent extends React.Component<IProfileLanguageProps> {
    private garudaApi: GarudaApi;
    private availableLangs: Language[];

    constructor(props: IProfileLanguageProps) {
        super(props);

        this.garudaApi = new GarudaApi();
    }

    private setDefaultLang() {
        const defaultLan = this.availableLangs?.find(al => al.name === this.props.respondent.selectedLanguage);
        if (defaultLan && !this.props.respondent.selectedLanguage) {
            this.props.selectLanguage(defaultLan.languageId);
        }
    }

    private renderAsList() {
        const selectedSubtoolData = this.props.toolData.SelectedSubTool(this.props.respondent.selectedToolId, this.props.respondent.selectedSubtoolId);

        const availableLanguageItems = [];

        if (selectedSubtoolData) {

            this.availableLangs = selectedSubtoolData.availableLanguages as Language[];

            for (const lng of this.availableLangs) {
                availableLanguageItems.push(<SelectListItem selectedValue={this.props.respondent.selectedLanguage} value={lng.languageId} label={lng.name}
                                                            onClick={() => this.props.selectLanguage(lng.languageId)} key={`language-${lng.languageId}`} />);
            }
        }

        return availableLanguageItems;
    }

    private renderAsDropdown() {
        const elements: JSX.Element[] = [];

        const availableLanguages = this.props.toolData.SelectedSubTool(this.props.respondent.selectedToolId, this.props.respondent.selectedSubtoolId).availableLanguages as Language[];

        elements.push(
            <select disabled={this.props.respondent.isLocked()} key={"language-select"} name={"\"language-select"} className="form-control" id={"language-select"} defaultValue={this.props.respondent.selectedLanguage}>
                <option />
                {availableLanguages?.map((language: Language, idx: number) => {
                    return (<option key={`${language.name}-${idx}`} value={language.languageId}>{`${language.name}`}</option>);
                })}
            </select>
        );

        return elements;
    }


    public componentDidMount() {
        const select2 = $("#language-select");
        if (select2 && select2.length > 0) {
            new DropdownModule().init(select2.get(0), {
                allowClear: false,
                searchable: true,
                placeholder: Localization.getText("Global_Select"),
                additionalSelect2Options: {
                    language: {
                        noResults: function () {
                            return Localization.getText("Global_NoResult");
                        }
                    }
                }
            });

            select2.on("select2:select", (e: any) => {
                const value = $("#language-select option:selected").val() as string;
                this.props.selectLanguage(value);
            });
        }

        this.setDefaultLang();
    }

    public componentDidUpdate() {
        this.setDefaultLang();
    }

    public render() {
        return (
            <>
                {!this.props.dropdown &&
                this.renderAsList()
                }

                {this.props.dropdown &&
                this.renderAsDropdown()
                }
            </>);
    }
}
