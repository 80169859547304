import { GarudaApi } from "../../infrastructure/garudaApi";
import {initializeEditGroupReactApp} from "../../focusGroupProfileEditModal";
import {GroupProfileTypeEnum} from "../../models/enums/groupProfileTypeEnum";
import {Localization} from "../localization-module";
import {ToolEnum} from "../../models/enums/toolEnum";
import {SubToolEnum} from "../../models/enums/subToolEnum";
import {initializeReactApp} from "../../editRespondent";

export class EditRespondentModalModule {
  private readonly garudaApi: GarudaApi;

  constructor() {
    this.garudaApi = new GarudaApi();
  }

  public initEditProfileModal(tool: ToolEnum, subtool: SubToolEnum, initializeCallback: (selector: JQuery<HTMLElement>) => void) {
    const garudaApi = this.garudaApi;
    const $this = this;

    $('#modal-edit-respondent').on('hidden.bs.modal', function () {
        $(this).find(".modal-body").empty();
    });

    $(".edit-single-respondent").off("click").on("click", async function () {
        const profileId = $(this).data("profile-id");
        const respondentId = $(this).data("respondent-id");
        const isGroup: boolean = $(this).data("is-group") as boolean;

        if (isGroup) {
            await $this.initEditGroupProfileModal(profileId, respondentId, initializeCallback);
        } else {
            garudaApi.getEditRespondentModal(profileId, respondentId)
                .then((html: string) => {
                    const modalSelector = $("#modal-edit-respondent");
                    modalSelector.modal("show");
                    const modalBody = $("#modal-edit-respondent-body");

                    modalBody.empty();
                    modalBody.html(html);

                    initializeReactApp(tool, subtool, modalSelector, initializeCallback);
                })
                .catch((reason) => {
                    console.log("error", reason);
                });
        }
    });
  }

  public async initEditGroupProfileModal(profileId: string, respondentId: string, initializeCallback: (selector: JQuery<HTMLElement>) => void) {
      this.garudaApi.getGroupProfileEditModal()
          .then(async (html: string) => {
              const modalSelector = $("#modal-edit-respondent");
              const saveButtonSelector = $("#modal-btn-update-respondent")
              modalSelector.modal("show");
              const modalBody = $("#modal-edit-respondent-body");

              modalBody.empty();
              modalBody.html(html);

              const group = await this.garudaApi.getGroupProfile(profileId);
              this.setModalTitle(group.type, modalSelector);

              initializeEditGroupReactApp(respondentId, group, modalSelector, saveButtonSelector, initializeCallback);
      })
  }

    private setModalTitle(type: GroupProfileTypeEnum, modalSelector: JQuery<HTMLElement>) {
        if(type === GroupProfileTypeEnum.Statisk) {
            const title = Localization.getText("General_Edit_GroupProfile_Static");
            modalSelector.find(".modal-header").find(".modal-title").html(title);
        } else if(type === GroupProfileTypeEnum.Dynamisk) {
            const title = Localization.getText("General_Edit_GroupProfile_Dynamic");
            modalSelector.find(".modal-header").find(".modal-title").html(title);
        }
    }
}
