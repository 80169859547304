import {Modal} from "./modules/modal-module";
import {GroupProfileComponent} from "./react/groupprofile-components/GroupProfileComponent";
import {renderComponent} from "./react/react-helper";
import * as React from "react";
import {GarudaApi} from "./infrastructure/garudaApi";
import {GroupProfileTypeEnum} from "./models/enums/groupProfileTypeEnum";
import {Localization} from "./modules/localization-module";
import {GroupProfileModel} from "./models/profileCrud/GroupProfileModel";

export const initializeEditGroupReactApp = (respondentId: string, group: GroupProfileModel, modalSelector: JQuery<HTMLElement>, saveButtonSelector: JQuery<HTMLElement>, saveCallback: (modalSelector: JQuery<HTMLElement>) => void) => {
    const reactContainer = document.getElementById("groupprofile-app-container");
    const component = <GroupProfileComponent
        saveCallback={() => saveCallback(modalSelector)}
        saveButtonSelector={saveButtonSelector}
        profileId={group.profileId}
        respondentId={respondentId}
        type={group.type}
        selectedProfileTags={group.selectedProfileTags}
        selectedProfiles={group.selectedProfiles}
        groupTags={group.selectedTags}
        name={group.name}
    />;
    renderComponent(component, reactContainer);
}

export class FocusGroupProfileEditModal {
    private readonly garudaApi: GarudaApi;

    constructor() {
        this.garudaApi = new GarudaApi();
    }

    public async initEditGroupProfileModal(saveButtonSelector: JQuery<HTMLElement>, saveCallback: (modalSelector: JQuery<HTMLElement>) => void ) {
        const modalId = "#new-focusgroup-profile";
        const editGroupModal = new Modal(
            ".edit-single-respondent",
            modalId,
            () => this.garudaApi.getGroupProfileEditModal(),
            async ($element) => {
                const profileId = $element.data("profile-id");
                const respondentId = $element.data("respondent-id");

                const group = await this.garudaApi.getGroupProfile(profileId);
                this.setModalTitle(group.type, editGroupModal);

                initializeEditGroupReactApp(respondentId, group, $(modalId), saveButtonSelector, saveCallback);
            });

        editGroupModal.init();
    }

    private setModalTitle(type: GroupProfileTypeEnum, modal: Modal) {
        if(type === GroupProfileTypeEnum.Statisk) {
            modal.setTitle(Localization.getText("General_Edit_GroupProfile_Static"))
        } else if(type === GroupProfileTypeEnum.Dynamisk) {
            modal.setTitle(Localization.getText("General_Edit_GroupProfile_Dynamic"))
        }
    }
}
