import * as React from "react";
import { ChangeEvent } from "react";
import { Localization } from "../../modules/localization-module";

interface ICheckLabelComponentProps {
    label: string;
    value: boolean;
    settingkey: string;
    licensed: boolean
    onSelected: (e: string) => void;
    disabled: boolean;
}

export class CheckLabelComponent extends React.Component<ICheckLabelComponentProps> {

    private onChange(e: ChangeEvent<HTMLInputElement>) {
        this.props.onSelected(e.target.checked.toString());
    }

    render() {
        return (
            <div className={"col-md-12"}>
                <div className="form-group flex-v-align-items">
                    <input disabled={this.props.disabled} defaultChecked={this.props.value} type="checkbox" id={this.props.settingkey} onChange={(e) => this.onChange(e)} />
                    <label className="checkbox-label" htmlFor={this.props.settingkey}>{this.props.label}</label>
                    {!this.props.licensed &&
                        <span className="flex-v-align-items" data-tooltip="white" data-placement="top" data-html="true" title={Localization.getText("Profile_Analysis_Missinglicense")}>
                            <svg className="embla-icon margin-left-s warning-fill"
                                aria-hidden="true"
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg">
                                    <use href="/dist/icons/sprite.symbol.svg#warning"></use>
                            </svg>
                        </span>
                    }
                </div>
            </div>);
    }
}
