import {GarudaApi} from "./infrastructure/garudaApi";
import {Modal} from "./modules/modal-module";
import {MergeRespondentsPostModel} from "./models/respondent/MergeRespondentsPostModel";
import {Localization} from "./modules/localization-module";

export class RespondentMerge {
    private readonly garudaApi: GarudaApi;
    private modal: Modal;
    private readonly respondentIds: string[];

    constructor(respondentIds: string[]) {
        this.garudaApi = new GarudaApi();
        this.respondentIds = respondentIds;
    }

    public async init(): Promise<void> {
        this.modal = new Modal(
            ".respondent-merge-button",
            "#respondent-merge-modal",
            () => this.getRespondentMergeModal(),
            () => {
                this.initPartial();
            },
            () => {
                this.applyModal().then(() => {
                    window.location.reload();
                })
            });

        await this.modal.init();
    }

    public async openModal(): Promise<void> {
        this.modal.open();
        await this.modal.loadModalContent();
    }

    private async getRespondentMergeModal(): Promise<string> {
        return this.garudaApi.getRespondentMergeModal(this.respondentIds);
    }

    private initPartial(): void {
        this.bindSelectPrimaryRespondent();
    }

    private primarySelectedClass = "primary-selected";
    private respondentMergeCardSelector = ".respondent-merge-card";
    private respondentMergeSetPrimarySelector = ".respondent-merge-set-primary";
    private primaryRespondentIdSelector = "#primary-respondent-id";

    private bindSelectPrimaryRespondent = () => {
        $(".respondent-merge-set-primary").off("click").on("click", (e) => {
            const $currentTarget = $(e.currentTarget);
            const respondentId = $currentTarget
                .closest(this.respondentMergeCardSelector)
                .data("respondent-id") as string;
            $(this.primaryRespondentIdSelector).val(respondentId);

            this.resetAllRespondents();
            this.setPrimaryRespondentStyling($currentTarget);
        });
    }

    private setPrimaryRespondentStyling = ($currentTarget: JQuery<HTMLElement>) => {
        const $parentCard = $currentTarget.closest(".respondent-merge-card");
        $parentCard.addClass(this.primarySelectedClass);

        const $setPrimaryBtn = $parentCard.find(this.respondentMergeSetPrimarySelector);
        $setPrimaryBtn.text(Localization.getText("Global_Selected"));
    }

    private resetAllRespondents = () => {
        $(this.respondentMergeCardSelector).removeClass(this.primarySelectedClass);
        $(this.respondentMergeCardSelector).find(this.respondentMergeSetPrimarySelector)
            .text(Localization.getText("Global_Select"));
    }

    public async applyModal() {
        const primaryRespondentId = $(this.primaryRespondentIdSelector).val() as string;
        const respondentsToMerge: string[] = this.getRespondentsToMerge(primaryRespondentId);
        const respondentMergePostModel = new MergeRespondentsPostModel(primaryRespondentId, respondentsToMerge);

        await this.garudaApi.mergeRespondentsPost(respondentMergePostModel);
    }

    private getRespondentsToMerge = (primaryRespondentId: string): string[] => {
        const respondentsToMerge: string[] = [];
        $(this.respondentMergeCardSelector).each((i,e) => {
            const respondentId = $(e).data("respondent-id");
            if(respondentId !== primaryRespondentId)
                respondentsToMerge.push(respondentId);
        });
        return respondentsToMerge;
    }
}
