import * as React from "react";

export const ProfileIcon = () => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="16" cy="16" r="16" fill="#233F6B"/>
            <mask id="mask0_381_8075" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="0" y="0" width="32" height="32">
                <circle cx="16" cy="16" r="16" fill="white"/>
            </mask>
            <g mask="url(#mask0_381_8075)">
                <path d="M21.2328 9.76663C21.2328 9.76663 22.37 15.1129 21.8753 18.8175C21.7778 19.5406 21.3888 20.196 20.7943 20.6384C20.1998 21.0808 19.449 21.2737 18.7082 21.1745C16.7977 20.9028 14.3015 19.9988 13.3348 17.2037L11.0945 12.6616C11.0945 12.6616 9.7924 9.76109 12.6639 7.20444C15.5353 4.64778 20.7097 6.47238 21.2328 9.76663Z" fill="#F5C0B2"/>
                <path d="M11.5183 13.2L10.8 31.6L20.8 31.2747L17.3095 18.021L11.5183 13.2Z" fill="#F5C0B2"/>
                <path d="M25.2 34C25.2 34 25.0518 29.4917 19.9078 28.2751C14.7638 27.0586 6.91026 23.137 6.00001 32.3398L25.2 34Z" fill="#233F6B"/>
                <path opacity="0.46" d="M25.2 34C25.2 34 25.0518 29.4917 19.9078 28.2751C14.7638 27.0586 6.91026 23.137 6.00001 32.3398L25.2 34Z" fill="#001940"/>
                <path d="M21.106 12.8L22.9555 14.7218C23.1699 14.9442 23.2504 15.2563 23.1687 15.5487C23.087 15.841 22.8546 16.073 22.5529 16.1632L20.4 16.8L21.106 12.8Z" fill="#F5C0B2"/>
                <path opacity="0.31" d="M18.1204 21.0806C16.8949 20.9253 15.7409 20.4107 14.8 19.6C14.8 19.6 15.318 22.821 19.2 25.6L18.1204 21.0806Z" fill="#CE8172"/>
            </g>
            <path fillRule="evenodd" clipRule="evenodd" d="M20.3258 11.3706C18.7081 11.9463 17.0029 12.2545 15.279 12.2827C15.279 12.2827 15.331 18.239 10.9807 20.5294C10.9807 20.5294 4.90722 12.7196 9.64486 8.41204C13.5953 4.85145 16.4799 1.71831 20.8423 4.38555C22.1141 5.16923 22.8436 6.58125 22.7351 8.04913C22.6266 9.51701 21.698 10.7972 20.3258 11.3706Z" fill="#03A9F4"/>
            <path d="M16 14.8677C16 14.8677 15.6625 12.4411 13.8774 12.8455C12.0923 13.25 12.5541 16.7641 15.0497 16.8L16 14.8677Z" fill="#F5C0B2"/>
        </svg>
    )
}