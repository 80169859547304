import * as React from "react";
import { Select2Component } from "../../react/ui-components/Select2Component";
import { SelectItemModel } from "../../models/SelectItemModel";

interface ITagModuleProps {
    select2Id: string
    options: SelectItemModel[];
    addTags: (selectedTags: string[]) => void;
    removeTags: (selectedTags: string[]) => void;
}

interface ITagModuleState {
    options: SelectItemModel[];
    inputValue: string;
}

export class TagModuleComponent extends React.Component<ITagModuleProps, ITagModuleState> {

    private addedtags : string[];

    constructor(props: Readonly<ITagModuleProps>) {
        super(props);

        this.state = {
            inputValue: "",
            options: []
        };
    }

    private handleChange() {
        const selectedTagValues = this.state.options.filter(o => o.selected).map(x => x.value);

        if (this.addedtags && this.addedtags.length > 0) {
            const tagsToRemove = this.addedtags.filter(at => selectedTagValues.indexOf(at) === -1);
            this.props.removeTags(tagsToRemove);
        }

        this.addedtags = selectedTagValues;
        this.props.addTags(selectedTagValues);
    }

    public async componentDidMount() {
        this.setState({
            options: this.props.options
        });
    }


    render() {
        return (
            <>
                <Select2Component multiple={true}
                                  options={this.state.options}
                                  select2Id={this.props.select2Id}
                                  itemSelected={() => this.handleChange()}
                                  itemUnselected={() => this.handleChange()}
                                  additionalSelect2Options={{tags: true}}
                />
            </>
        );
    }
}
