import * as React from "react";
import {ChangeEvent} from "react";
import {Localization} from "../../../modules/localization-module";
import {RespondentModel} from "../../../models/profileCrud/RespondentModel";
import {GarudaApi} from "../../../infrastructure/garudaApi";
import {Analysis, ToolCrudModel} from "../../../models/profileCrud/ToolCrudModel";
import {toolGarudaLogic} from "../../../infrastructure/constants";
import {DropdownModule} from "ditmer-embla";
import {EmblaIcon} from "../../ui-components/EmblaIcon";

interface IProfileAddGarudaLogicProps {
    respondent: RespondentModel;
    toolData: ToolCrudModel;
    hasLicenceForGarudaLogic: boolean;
    selectGarudaLogicSubtool: (subToolId?: string) => void;
}

interface IProfileAddGarudaLogicState {
    isAllowedToAddGarudaLogic: boolean
}

export class ProfileAddGarudaLogicComponent extends React.Component<IProfileAddGarudaLogicProps, IProfileAddGarudaLogicState> {
    private garudaApi: GarudaApi;

    constructor(props: IProfileAddGarudaLogicProps) {
        super(props);
        this.garudaApi = new GarudaApi();

        this.state = {
            isAllowedToAddGarudaLogic: false
        }
    }

    public componentDidMount() {
        this.updateDropdownAvailability();
    }

    public async componentDidUpdate(prevProps: Readonly<IProfileAddGarudaLogicProps>, prevState: Readonly<IProfileAddGarudaLogicState>) {
        this.initGarudaLogicDropdown();
    }

    private updateDropdownAvailability() {
        const garudaLogicTools = this.props.toolData.tools.filter(t => t.toolId === toolGarudaLogic);
        const isAllowedToAddGarudaLogic = garudaLogicTools.length > 0;

        this.setState({
            isAllowedToAddGarudaLogic: isAllowedToAddGarudaLogic
        });
    }

    private initGarudaLogicDropdown() {
        const dropdownSelector = $("#garuda-logic-subtools");
        new DropdownModule().init(dropdownSelector.get(0), {
            placeholder: Localization.getText("Global_Select"),
            allowClear: true,
            additionalSelect2Options: {
                language: {
                    noResults: function () {
                        return Localization.getText("Global_NoResult");
                    }
                }
            }
        });

        // @ts-ignore
        dropdownSelector.on("select2:unselect", (e: ChangeEvent<HTMLOptionElement>) => {
            const value = e.currentTarget.value;
            this.props.selectGarudaLogicSubtool(value);
        });

        // @ts-ignore
        dropdownSelector.on("select2:select", (e: ChangeEvent<HTMLOptionElement>) => {
            const value = e.currentTarget.value;
            this.props.selectGarudaLogicSubtool(value);
        });
    }

    private getDropdownItems() {
        return this.props.toolData.tools.find(t => t.toolId === toolGarudaLogic).analysis.map((item: Analysis) => {
            return (<option value={item.toolSubId} key={item.toolSubId}>{item.name}</option>);
        });
    }

    public render() {
        return (
            <>
                <div className={"col-sm-12"}>
                    {this.state.isAllowedToAddGarudaLogic &&
                    <div className={"form-group"}>
                      <label htmlFor={"garuda-logic-subtools"}>
                          {Localization.getText("Profile_Add_GarudaLogic")}
                        <span className="header-info-icon-wrapper margin-left-xs">
                          <EmblaIcon iconName="info"
                                     onMouseMove={(e) => window.showTooltip(e, '', '', Localization.getText("Profile_Add_GarudaLogic_Info"), null)}
                                     onMouseOut={window.hideTooltip}/>
                        </span>
                      </label>
                      <select id={"garuda-logic-subtools"} defaultValue={ this.props.respondent.garudaLogicSubToolId ? this.props.respondent.garudaLogicSubToolId : ""} className={"form-control"}
                              name={"garuda-logic-subtool-select"}>
                        <option/>
                          {this.getDropdownItems()}
                      </select>
                    </div>
                    }

                    {!this.state.isAllowedToAddGarudaLogic &&
                    <div className={"card m-0"}>
                      <div className={"card-body d-flex p-2"}>
                        <div className={"toolbox-icon-colored-wrapper"}>
                          <EmblaIcon iconName={"toolbox"} classes={"toolbox-icon-colored"}/>
                        </div>
                        <h5 className="ml-2 subtle align-self-center">
                            {Localization.getText("Profile_Add_GarudaLogic_NotIncluded_Info")}
                          <span className="header-info-icon-wrapper margin-left-xs">
                            <EmblaIcon iconName="info"
                                       onMouseMove={(e) => window.showTooltip(e, '', '', Localization.getText("Tool_NotIncluded_Info"), null)}
                                       onMouseOut={window.hideTooltip}/>
                          </span>
                        </h5>
                      </div>
                    </div>
                    }
                </div>
            </>
        );
    }
}
