import { GarudaApi } from "../../infrastructure/garudaApi";
import { DummyTooltipModule } from "../dummy-tooltip-module";
import { Modal } from "../modal-module";

export class RespondentMiniProfileModalModule {
  private readonly garudaApi: GarudaApi;
  private readonly miniProfileModal: Modal;

  constructor(triggerSelector: string, modalId: string, getProfileId: (clickedElement: JQuery<HTMLElement>) => string, initializedCallback?: () => void) {
    this.garudaApi = new GarudaApi();

    this.miniProfileModal = new Modal(
      triggerSelector,
      modalId,
      (triggerElement) => this.garudaApi.getMiniatureGraphs(getProfileId(triggerElement)),
      () => {
        new DummyTooltipModule().init();

        if(initializedCallback)
          initializedCallback();
      },
      (url) => { window.location.href = url; }
    );
  }

  public initMiniProfileModal() {
    this.miniProfileModal.init();
  }
}
