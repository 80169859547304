import * as React from "react";
import {renderComponent} from "./react/react-helper";
import {RespondentApp} from "./react/respondent-components/RespondentApp";
import {TooltipModule} from "ditmer-embla";
import {ToolEnum} from "./models/enums/toolEnum";
import {SubToolEnum} from "./models/enums/subToolEnum";
import {initCustomTooltips} from "./modules/custom-tooltip-module";

export const initializeReactApp = (tool: ToolEnum, subtool: SubToolEnum, modalSelector: JQuery<HTMLElement>, saveCallback: (selector: JQuery<HTMLElement>) => void,) => {
    const respondentId = $("#respondentId").val() as string;
    const profileId = $("#profileId").val() as string;
    const reactContainer = document.getElementById("react-app");

    renderComponent((<RespondentApp isEdit={true}
                                    respondentId={respondentId}
                                    profileId={profileId}
                                    saveCallbackButtonSelector={updateRespondentButtonSelector}
                                    saveCallback={() => {
                                        saveCallback(modalSelector);
                                        $("#modal-edit-respondent").modal("hide");
                                    }}
                                    saveCallbackValidation={(error: string) => Validate(error)}
                                    showAnalysisComponent={tool !== ToolEnum.All}
                                    redirectUrl={""}
                                    initCustomTooltips={() => initCustomTooltips()}
                                    subtool={subtool}/>
    ), reactContainer);
};

const updateRespondentButtonSelector = "#modal-btn-update-respondent";

const Validate = (error: string) => {
    const validationMessageSpan = `<span data-tooltip="white" data-placement="top" data-html="true" title="${error}" data-original-title="${error}"></span>`

    if (error) {
        $(updateRespondentButtonSelector).addClass("disabled-button");
        $(updateRespondentButtonSelector).wrap(validationMessageSpan);
        new TooltipModule($("[data-tooltip]"));
    } else if ($(updateRespondentButtonSelector).parent().is("span")) {
        $(updateRespondentButtonSelector).removeClass("disabled-button");
        $(updateRespondentButtonSelector).unwrap();
    }
}
