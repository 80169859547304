export class MergeRespondentsPostModel {

    constructor(primaryRespondentId: string, respondentsToMerge: string[]) {
        this.primaryRespondentId = primaryRespondentId;
        this.respondentsToMerge = respondentsToMerge;

    }

    private primaryRespondentId: string;
    private respondentsToMerge: string[];
}
