import {ToolEnum} from "../models/enums/toolEnum";
import {SubToolEnum} from "../models/enums/subToolEnum";
import {
    basisProfileRespondentListUrl,
    copJobProfileListUrl,
    copJobProfileUrl,
    copMirrorProfileListUrl,
    copPersonProfileListUrl,
    copPersonProfileUrl,
    copReferenceProfileListUrl,
    eSportMirrorProfileListUrl,
    eSportPersonProfileListUrl,
    focusDreamProfileListUrl, focusGroupProfileListUrl,
    focusGroupProfileUrl,
    focusJobProfileListUrl,
    focusMirrorProfileListUrl, focusPersonProfileListUrl,
    focusPersonProfileUrl,
    footballPersonProfileListUrl,
    garudaLogicRespondentListUrl,
    golfMirrorProfileListUrl,
    golfPersonProfileListUrl,
    handballMirrorProfileListUrl,
    handballPersonProfileListUrl
} from "../models/constants/apiConstants";
import {profileStatusEnum} from "../models/enums/profileStatusEnum";

export const getBaseUrl = (): string => {
    return document.getElementsByTagName("base")[0].href;
}

export const getProfileUrl = (profileId: string, tool: ToolEnum, subTool: SubToolEnum, profileName: string = "", profileStatus: profileStatusEnum = profileStatusEnum.Sent): string => {
    const url = getBaseUrl();
    const completed = profileStatus === profileStatusEnum.Completed;

    switch (tool) {
        case ToolEnum.KompetenceProfile:
            switch (subTool) {
                case SubToolEnum.Job:
                    const jobName = profileName.split(/[()]+/).join('');
                    return completed
                        ? url.concat(copJobProfileUrl, `?profileId=${profileId}`)
                        : url.concat(copJobProfileListUrl, `?searchedName=${jobName}`);
                case SubToolEnum.Mirror:
                    return url.concat(copMirrorProfileListUrl, `?searchedName=${profileName}`);
                case SubToolEnum.Person:
                    return completed
                        ? url.concat(copPersonProfileUrl, `?profileId=${profileId}`)
                        : url.concat(copPersonProfileListUrl, `?searchedName=${profileName}`);
                case SubToolEnum.Reference:
                    return url.concat(copReferenceProfileListUrl, `?searchedName=${profileName}`);
            }
            break;
        case ToolEnum.FocusProfile:
            switch (subTool) {
                case SubToolEnum.FocusPerson:
                    return completed
                        ? url.concat(focusPersonProfileUrl, `?profileId=${profileId}`)
                        : url.concat(focusPersonProfileListUrl, `?searchedName=${profileName}`);
                case SubToolEnum.FocusGroup:
                    return completed
                        ? url.concat(focusGroupProfileUrl, `?profileId=${profileId}`)
                        : url.concat(focusGroupProfileListUrl, `?searchedName=${profileName}`);
                case SubToolEnum.FocusJob:
                    const jobName = profileName.split(/[()]+/).join('');
                    return url.concat(focusJobProfileListUrl, `?searchedName=${jobName}`);
                case SubToolEnum.FocusMirror:
                    return url.concat(focusMirrorProfileListUrl, `?searchedName=${profileName}`);
                case SubToolEnum.FocusDreamPosition:
                    return url.concat(focusDreamProfileListUrl, `?searchedName=${profileName}`);
            }
            break;
        case ToolEnum.BasisProfile:
            switch (subTool) {
                case SubToolEnum.BasisPerson:
                    return url.concat(basisProfileRespondentListUrl, `?searchedName=${profileName}`);
            }
            break;
        case ToolEnum.ESportProfile:
            switch (subTool) {
                case SubToolEnum.ESportPerson:
                    return url.concat(eSportPersonProfileListUrl, `?searchedName=${profileName}`);
                case SubToolEnum.ESportMirror:
                    return url.concat(eSportMirrorProfileListUrl, `?searchedName=${profileName}`);
            }
            break;
        case ToolEnum.FootballProfile:
            switch (subTool) {
                case SubToolEnum.FootballPerson:
                    return url.concat(footballPersonProfileListUrl, `?searchedName=${profileName}`);
            }
            break;
        case ToolEnum.GarudaLogicProfile:
            switch (subTool) {
                case SubToolEnum.GarudalogicVersionA:
                case SubToolEnum.GarudalogicVersionB:
                case SubToolEnum.GarudalogicVersionC:
                case SubToolEnum.GarudalogicVersionD:
                case SubToolEnum.GarudalogicVersionA1:
                case SubToolEnum.GarudalogicVersionB1:
                    return url.concat(garudaLogicRespondentListUrl, `?searchedName=${profileName}`);
            }
            break;
        case ToolEnum.GarudaProfile:
            // TODO: What is this tool?
            break;
        case ToolEnum.GolfProfile:
            switch (subTool) {
                case SubToolEnum.GolfPerson:
                    return url.concat(golfPersonProfileListUrl, `?searchedName=${profileName}`);
                case SubToolEnum.GolfMirror:
                    return url.concat(golfMirrorProfileListUrl, `?searchedName=${profileName}`);
            }
            break;
        case ToolEnum.HandballProfile:
            switch (subTool) {
                case SubToolEnum.HandballPerson:
                    return url.concat(handballPersonProfileListUrl, `?searchedName=${profileName}`);
                case SubToolEnum.HandballMirror:
                    return url.concat(handballMirrorProfileListUrl, `?searchedName=${profileName}`);
            }
            break;

        // TODO: New tools
        case ToolEnum.LeadersMentalScorecard:
            break;
    }

    return "";
}
