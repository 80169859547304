import * as React from "react";
import { EmblaIcon } from "./EmblaIcon";

interface IBadgePillProps {
    classes?: string;
    iconName?: string;
    onClick?: () => void;
}

export const BadgePill = (props: React.PropsWithChildren<IBadgePillProps>) => {
    const additionalClasses = props.classes ? ` ${props.classes}` : "";

    return (
        <span onClick={props.onClick} className={`badge badge-pill padding-s ${additionalClasses}`}>
            {props.children}
            {props.iconName && <EmblaIcon iconName={props.iconName} classes="margin-left-xs" />}
        </span>
    );
}
