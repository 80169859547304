import {CustomTooltipModule} from "./custom-tooltip-module";

export { CustomTooltipModule } from "./custom-tooltip-module";

export const initCustomTooltips = () => {
    const tooltipModule = new CustomTooltipModule({
        tooltipContainerIdSelector: "#custom-tooltip-container"
    });

    tooltipModule.init();
}
