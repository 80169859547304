import * as React from "react";
import {RespondentModel} from "../../models/profileCrud/RespondentModel";
import {RespondentProfilesSplitViewModel} from "../../models/respondent/RespondentProfilesSplitViewModel";
import {RespondentSplitProfile} from "./RespondentSplitProfile";
import {ProfileIdentifierViewModel} from "../../models/respondent/ProfileIdentifierViewModel";

interface RespondentSplitProps {
    respondent: RespondentModel;
    respondentSplitViewModel: RespondentProfilesSplitViewModel;
    saveCallback?: () => void;
}

interface RespondentSplitState {
    showSplitButton: boolean;
}

export class RespondentSplit extends React.Component<RespondentSplitProps, RespondentSplitState> {
    constructor(props: RespondentSplitProps) {
        super(props);

        this.state = {
            showSplitButton: this.showSplitButton(this.props.respondentSplitViewModel.profileIdentifierViewModels)
        }
    }

    private showSplitButton = (profiles: ProfileIdentifierViewModel[]): boolean => {
        const numOfProfiles = profiles.reduce((prev, next) => {
            return prev + next.subProfiles.length
        }, 0);
        return numOfProfiles > 1;
    }

    render() {
        return (
            <>
                <div className="row">
                    <div className="col-12">
                        <div className="split-profiles-container">
                            {this.props.respondentSplitViewModel.profileIdentifierViewModels.map((profile) => {
                                return <RespondentSplitProfile
                                    key={profile.profileId}
                                    profile={profile}
                                    respondent={this.props.respondent}
                                    showSplitButton={this.state.showSplitButton}
                                    saveCallback={this.props.saveCallback}
                                />
                            })}
                        </div>
                    </div>
                </div>
            </>);
    }
}
