import { GarudaApi } from "../../infrastructure/garudaApi";
import { Spinner } from "../spinner-module";

export class InterpretationPanel {

    private readonly garudaApi: GarudaApi;
    private readonly spinnerController: Spinner;
    private readonly interpretationsSelector: string = ".interpretation-body";
    private readonly interpretationButtonsSelector: string = ".interpretation-buttons";
    private readonly interpretationContentSelector: string = ".interpretation-content";

    private readonly interpretationNotesContentSelector: string = ".interpretations-note-content";

    constructor() {
        this.garudaApi = new GarudaApi();
        this.spinnerController = new Spinner("#interpretation-spinner");
    }

    public async initInterpretations(profileId: string, traitId: string, comparedProfileIds: string[]) {
        this.spinnerController.showSpinner();

        let threadId = $(".interpretation-content:visible").find(".tab-content").find(".active").attr("threadid");
        if (!threadId){
            threadId = $("#SelectedThreadId").val() as string;
        }
        const selectedTabCaption = $("#SelectedTabCaption").val() as string;

        const partial = await this.garudaApi.getInterpretationPartial(profileId, traitId, comparedProfileIds, threadId, selectedTabCaption);
        $(this.interpretationsSelector).empty();
        $(this.interpretationsSelector).append(partial);

        this.showInterpretationContent();
        this.initButtons();

        this.spinnerController.hideSpinner();
    }

    private initButtons() {
        const $this = this;
        const buttons = $(this.interpretationButtonsSelector).children("button");
        buttons.each(function (index, element) {
            $(element).off("click").on("click", function () {
                buttons.removeClass("active");
                $(this).addClass("active");
                $this.showInterpretationContent();
            });
        });
    }

    private showInterpretationContent() {
        const selectedLabel = this.getSelectedId();
        $(this.interpretationContentSelector).hide();
        $(this.interpretationNotesContentSelector).hide();
        $(`[data-id=${selectedLabel}]`).show();
    }

    private getSelectedId(): string {
        return $(this.interpretationButtonsSelector).children(".active").data("id");
    }
}
