import * as React from "react";
import { RespondentModel } from "../../models/profileCrud/RespondentModel";
import { ProfileIdentifierViewModel } from "../../models/respondent/ProfileIdentifierViewModel";
import { GarudaApi } from "../../infrastructure/garudaApi";
import { SplitRespondentProfilePostModel } from "../../models/respondent/SplitRespondentProfilePostModel";
import { Localization } from "../../modules/localization-module";
import { profileStatusEnum } from "../../models/enums/profileStatusEnum";
import { getProfileUrl } from "../../infrastructure/apiHelper";

interface RespondentSplitProfileProps {
    profile: ProfileIdentifierViewModel;
    respondent: RespondentModel;
    showSplitButton: boolean;
    saveCallback?: () => void;
}

interface RespondentSplitProfileState {
    isValidationActive: boolean;
}

export class RespondentSplitProfile extends React.Component<RespondentSplitProfileProps, RespondentSplitProfileState> {
    private readonly garudaApi: GarudaApi;

    constructor(props: RespondentSplitProfileProps) {
        super(props);
        this.garudaApi = new GarudaApi();

        this.state = {
            isValidationActive: false
        }
    }

    private async handleSplitProfile() {
        const model = new SplitRespondentProfilePostModel(this.props.respondent.id, this.props.profile.profileId);
        await this.garudaApi.splitRespondentProfilePost(model)
            .then(() => {
                this.props.saveCallback();
            });
    }

    private showAreYouSureValidation() {
        this.setState({
            isValidationActive: true
        });
    }

    private hideAreYouSureValidation() {
        this.setState({
            isValidationActive: false
        });
    }

    private handleShowButton() {
        const subProfile = this.props.profile.subProfiles[0];
        location.href=getProfileUrl(this.props.profile.profileId, this.props.profile.profileToolEnum, subProfile.toolSubEnum, this.props.profile.profileName, subProfile.status);
    }

    render() {
        return (
            <>
                <div className="split-profile">
                    <div className="profile-badge-container">
                        <div className={"profile-badge " + this.props.profile.profileAbbreviation}>
                            {this.props.profile.profileAbbreviation}
                        </div>
                        <div>
                            <div>
                                {this.props.profile.profileLabel}
                            </div>
                            <div className="small d-flex flex-column">
                                {this.props.profile.subProfiles.map(sub => {
                                    if(sub.status === profileStatusEnum.Completed) return `${sub.toolSubName} `;

                                    return <span className="subtle">{sub.toolSubName} ({Localization.getText("General_NotCompleted").toLowerCase()}) </span>;
                                })}
                            </div>
                        </div>
                    </div>

                    <div className="flex-display">
                        {!this.state.isValidationActive &&
                            this.props.showSplitButton &&
                        <input type="button"
                               className="btn btn-default margin-left-s"
                               value={Localization.getText("Global_Split")}
                               onClick={() => this.showAreYouSureValidation()}/>
                        }

                        {this.state.isValidationActive &&
                        <div className="btn-group confirm margin-left-s" role="group">
                            <button type="button" className="btn btn-danger btn-icon btn-outline"
                                    onClick={() => this.hideAreYouSureValidation()}>
                                <svg className="embla-icon danger-fill" aria-hidden="true"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <use xlinkHref="/dist/icons/sprite.symbol.svg#danger"/>
                                </svg>
                            </button>
                            <button type="button" className="btn btn-success btn-icon btn-outline"
                                    onClick={() => this.handleSplitProfile()}>
                                <svg className="embla-icon success-fill" aria-hidden="true"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <use xlinkHref="/dist/icons/sprite.symbol.svg#success"/>
                                </svg>
                            </button>
                        </div>
                        }

                        <input type="button"
                               className="btn btn-default margin-left-s"
                               value={Localization.getText("Global_Show")}
                               onClick={() => this.handleShowButton()}/>
                    </div>

                </div>
            </>);
    }
}
