export class TransferOwnershipPostModel {
    respondentsIds: string[];
    keepSharing: boolean;
    userId: string;
    userName: string;
    userEmail: string;

    constructor(respondentsIds: string[], keepSharing: boolean, userId: string, userName?: string, userEmail?: string) {
        this.respondentsIds = respondentsIds;
        this.keepSharing = keepSharing;
        this.userId = userId;
        this.userName = userName;
        this.userEmail = userEmail;
    }
}
