import {SettingsValueModel} from "./SettingsValueModel";
import {profileStatusEnum} from "../enums/profileStatusEnum";
import {Localization} from "../../modules/localization-module";
import {SelectItemModel} from "../SelectItemModel";
import {Analysis, ToolCrudModel, ToolModel} from "./ToolCrudModel";
import {
    settingsConstantsProfileAssociation,
    subtoolMirror,
    subtoolMirrorFollowup
} from "../../infrastructure/constants";

export class RespondentModel {

    constructor() {
        this.tags = [];
        this.questionnaireTags = [];
        this.settingValues = [];
        this.specialSettingValues = [];
        this.profileSharing = [];
    }

    id: string;
    profileId: string;
    name: string;
    email: string;
    countryCode: string;
    phone: string;
    tags: SelectItemModel[];
    questionnaireTags: SelectItemModel[];
    profilstatus: profileStatusEnum;
    respondentType: number;
    selectedToolId: string;
    selectedSubtoolId: string;
    selectedLanguage: string;
    settingValues: SettingsValueModel[];
    specialSettingValues: SettingsValueModel[];
    associatedProfileName: string;
    profileSharing: string[];
    expiryDate: string;
    garudaLogicSubToolId?: string;
    additionalGarudaLogicProfileId?: string;
    selectedTags: string | string[];
    settingValuesToNulstillingOnMapping?: Map<string,string> = new Map<string, string>();

    public plainToClass() {
        // manual plain to class
        const settings: SettingsValueModel[] = [];
        this.settingValues.forEach(x => {
            settings.push(new SettingsValueModel(x.key, x.value, x.validate));
        });

        // Add extra validation rules for profile association
        if(settings.find(c=>c.key === settingsConstantsProfileAssociation) === undefined)
            settings.push(new SettingsValueModel(settingsConstantsProfileAssociation, null, true));

        this.settingValues = settings;

        const specialSettingValues: SettingsValueModel[] = [];
        this.specialSettingValues.forEach(x => {
            specialSettingValues.push(new SettingsValueModel(x.key, x.value, x.validate));
        });
        this.specialSettingValues = specialSettingValues;
    }

    public setSettingValuesToNulstillingOnMapping(valueList: Map<string,string>) {
        this.settingValuesToNulstillingOnMapping = valueList;
    }

    public setSettingsDefaultValues(toolCrudModel: ToolCrudModel) {
        toolCrudModel.AllQuestionarySettings().forEach(s => {
            if (!this.settingValues.some(sv => sv.key === s.key)) {
                const newValue = new SettingsValueModel(s.key, s.value.toString(), s.validate)
                this.settingValues.push(newValue);
            }
        })

        toolCrudModel.commonSettings.checkLabels.forEach(s => {
            if (!this.settingValues.some(sv => sv.key === s.key)) {
                const newValue = new SettingsValueModel(s.key, s.value.toString(), false)
                this.settingValues.push(newValue);
            }
        })

        toolCrudModel.commonSettings.checkDates.forEach(s => {
            if (!this.settingValues.some(sv => sv.key === s.key)) {
                const newValue = new SettingsValueModel(s.key, null, false)
                this.settingValues.push(newValue);
            }
        })

        const firstTool: ToolModel = toolCrudModel.tools[0];
        const firstAnalysis: Analysis = firstTool.analysis[0];

        if (!this.settingValues.some(sv => sv.key === firstAnalysis.purposeSettings[0].key)) {
            const newValue = new SettingsValueModel(firstAnalysis.purposeSettings[0].key, null, true)
            this.settingValues.push(newValue);
        }
    }

    public isLocked(): boolean {
        return this.profilstatus && this.profilstatus !== profileStatusEnum.Sent && this.profilstatus !== profileStatusEnum.Created;
    }

    public respondentNewValid(): string {
        if (!this.name || this.name?.trim() === "")
            return Localization.getText("Global_Fill_Fields");

        if (!this.email || this.email?.trim() === "")
            return Localization.getText("Global_Fill_Fields");

        const emailValid = this.email.match("^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$");
        if (emailValid === null)
            return Localization.getText("Global_Email_Invalid");

        return "";
    }

    public respondentExistingValid(): string {
        if (this.id){
            return "";
        }
        else{
            return Localization.getText("Global_Fill_Fields");
        }
    }

    public profileValid(allQuestionnaireSettingKeys: string[]): string {
        if (this.settingValues) {
            const questionnaireSettings = this.settingValues
                .filter(sv => sv.validate)
                .filter(sv => allQuestionnaireSettingKeys.indexOf(sv.key) > -1);

            if (questionnaireSettings?.length > 0){
                if (questionnaireSettings.filter(sv => sv.isChecked())?.length < 2) {
                    return Localization.getText("Global_Fill_Fields");
                }
            }

            let validationSettings = this.settingValues.filter(sv => sv.validate)
                .filter(sv => allQuestionnaireSettingKeys.indexOf(sv.key) < 0);

            // Remove profile association validation setting if not mirror
            if (this.selectedSubtoolId != subtoolMirror && this.selectedSubtoolId != subtoolMirrorFollowup) {
                validationSettings = validationSettings.filter(vs => vs.key != settingsConstantsProfileAssociation);
            }

            if (validationSettings.some(setting => !setting.value)) {
                return Localization.getText("Global_Fill_Fields");
            }
        }

        if (!this.selectedToolId || !this.selectedSubtoolId || !this.selectedLanguage) {
            return Localization.getText("Global_Fill_Fields");
        }

        return "";
    }

    public mapper_LocalStorage(srcRespondent: RespondentModel, distRespondent: RespondentModel): RespondentModel {
        distRespondent.selectedSubtoolId = srcRespondent.selectedSubtoolId;
        distRespondent.selectedToolId = srcRespondent.selectedToolId;
        distRespondent.respondentType = srcRespondent.respondentType;
        distRespondent.specialSettingValues = [];
        distRespondent.garudaLogicSubToolId = srcRespondent.garudaLogicSubToolId;
        if(distRespondent.settingValuesToNulstillingOnMapping !== null) {
            srcRespondent.settingValues.map(sv=> {
                const value = distRespondent.settingValuesToNulstillingOnMapping.get(sv.key);
                if(value !== undefined)
                    sv.value = value;
            });
        }
        distRespondent.settingValues = srcRespondent.settingValues;
        distRespondent.selectedLanguage = srcRespondent.selectedLanguage;
        distRespondent.plainToClass();

        return distRespondent;
    }
}
