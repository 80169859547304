import {GarudaApi} from "../../infrastructure/garudaApi";
import {GraphicTypeEnum} from "../../models/enums/graphTypeEnum";
import {GraphSwitchEnum} from "../../models/enums/graphSwitchEnum";
import {GraphSwitchModule} from "../graph-switch-module";
import {SubToolEnum} from "../../models/enums/subToolEnum";
import {AnalysisPageEnum} from "../../models/enums/analysisPageEnum";

interface IGrafSettings {
    profileId: string;
    typeIds: string[];
    grafContainerSelector: string;
    graphicType: GraphicTypeEnum;
    subToolEnum?: SubToolEnum;
    afterContentRendered?: () => void,

    // Graph specific settings
    graphCombined?: boolean;
    outerScoreIndicator?: boolean;
    fewAnswerIndicator?: boolean;
    graphCaption?: boolean;
    analysisPage?: AnalysisPageEnum;
    isGroup?: boolean;
    highlightedProfile?: string;
    hiddenProfiles?: string[];
    changeScore?: boolean;
    showContextMenu?: boolean;
}

export class GrafModule {
    private readonly garudaApi: GarudaApi;
    private readonly typeIds: string[];
    private readonly profileId: string;
    private readonly graphicType: GraphicTypeEnum;
    private readonly graphicsContainer: string;
    private readonly graphSwitchesModule: GraphSwitchModule;
    private readonly subToolEnum?: SubToolEnum;
    private readonly afterContentRendered?: () => void;

    // Graph specific settings
    private readonly graphCombined: boolean;
    private readonly outerScoreIndicator: boolean;
    private readonly fewAnswerIndicator: boolean;
    private readonly graphCaption: boolean;
    private readonly analysisPage?: AnalysisPageEnum;
    private readonly isGroup?: boolean;
    private readonly highlightedProfile?: string;
    private readonly hiddenProfiles?: string[];
    private readonly changeScore?: boolean;
    private readonly showContextMenu?: boolean;

    constructor(settings: IGrafSettings) {
        this.garudaApi = new GarudaApi();
        this.graphSwitchesModule = new GraphSwitchModule({});
        this.typeIds = settings.typeIds;
        this.profileId = settings.profileId;
        this.graphicsContainer = settings.grafContainerSelector;
        this.graphicType = settings.graphicType;
        this.subToolEnum = settings.subToolEnum;
        this.afterContentRendered = settings.afterContentRendered

        this.graphCombined = settings.graphCombined;
        this.outerScoreIndicator = settings.outerScoreIndicator;
        this.fewAnswerIndicator = settings.fewAnswerIndicator;
        this.graphCaption = settings.graphCaption;
        this.analysisPage = settings.analysisPage;
        this.isGroup = settings.isGroup;
        this.highlightedProfile = settings.highlightedProfile;
        this.hiddenProfiles = settings.hiddenProfiles;
        this.changeScore = settings.changeScore;
        this.showContextMenu = settings.showContextMenu;
    }

    public init(additionalProfileIds: string[] = null) {
        const showExpandedTraits = this.graphSwitchesModule.getSwitchValue(GraphSwitchEnum.ExpandedTraits.toString());

        this.typeIds.forEach((typeId, index) => {
            let allProfileIds: string[] = [this.profileId];

            const graphicContainer = $(this.graphicsContainer);
            const graphicsContainerElement = graphicContainer.find(`#${typeId}`);

            graphicsContainerElement.hide();
            graphicsContainerElement.siblings(".spinner").show();
            graphicsContainerElement.find(".spinner").show();

            if (additionalProfileIds?.length > 0) {
                allProfileIds = allProfileIds.concat(additionalProfileIds);
            }

            if (this.graphicType === GraphicTypeEnum.barChart) {
                this.garudaApi.getSingleBarChartData(allProfileIds, typeId).then((html: string) => {
                    GrafModule.showGraphic(html, graphicsContainerElement);
                });
            }

            if (this.graphicType === GraphicTypeEnum.graph) {
                this.garudaApi.getGrafData(allProfileIds, typeId, showExpandedTraits, this.graphCombined, this.outerScoreIndicator, this.fewAnswerIndicator, this.showContextMenu, this.subToolEnum, this.analysisPage).then((html: string) => {
                    GrafModule.showGraphic(html, graphicsContainerElement);
                    this.afterContentRendered();
                });
            }

            if (this.graphicType === GraphicTypeEnum.graphSingle) {
                // Only shows the first context menu in case of multiple graphs as they will always be the same (see CombinedTraits)
                const show = this.showContextMenu && index === 0;

                this.garudaApi.getSingleGrafData(allProfileIds, typeId, showExpandedTraits, this.graphCaption, this.changeScore, show, this.subToolEnum, this.analysisPage).then((html: string) => {
                    GrafModule.showGraphic(html, graphicsContainerElement);
                    this.afterContentRendered();
                });
            }

            if (this.graphicType === GraphicTypeEnum.barometerWithPanelheader) {
                this.garudaApi.getBarometerWithHeader(this.profileId, typeId, this.subToolEnum).then((html: string) => {
                    GrafModule.showGraphic(html, graphicsContainerElement);
                });
            }

            if (this.graphicType === GraphicTypeEnum.barometer) {
                this.garudaApi.getBarometerData(this.profileId, typeId, true, this.subToolEnum).then((html: string) => {
                    GrafModule.showGraphic(html, graphicsContainerElement);
                    this.afterContentRendered();
                });
            }

            if (this.graphicType === GraphicTypeEnum.barometerClean) {
                this.garudaApi.getBarometerData(this.profileId, typeId, false, this.subToolEnum).then((html: string) => {
                    GrafModule.showGraphic(html, graphicsContainerElement);
                });
            }

            if (this.graphicType === GraphicTypeEnum.energy) {
                this.garudaApi.getEnergyGraphic(this.profileId, true).then((html: string) => {
                    GrafModule.showGraphic(html, graphicsContainerElement);
                });
            }

            if (this.graphicType === GraphicTypeEnum.energyWithHeader) {
                this.garudaApi.getEnergyWithHeaderGraphic(this.profileId, true).then((html: string) => {
                    GrafModule.showGraphic(html, graphicsContainerElement);
                });
            }

            if (this.graphicType === GraphicTypeEnum.chartWithHeader) {
                this.garudaApi.getChartWithHeader(this.profileId).then((html: string) => {
                    GrafModule.showGraphic(html, graphicsContainerElement);
                });
            }

            if (this.graphicType === GraphicTypeEnum.weightChart) {
                this.garudaApi.getWeightChart(this.profileId, typeId).then((html: string) => {
                    GrafModule.showGraphic(html, graphicsContainerElement);
                });
            }

            if (this.graphicType === GraphicTypeEnum.keywords) {
                this.garudaApi.getKeywordChart(this.profileId, this.typeIds[0], this.typeIds[1]).then((html: string) => {
                    GrafModule.showGraphic(html, graphicsContainerElement);
                })
            }

            if (this.graphicType === GraphicTypeEnum.benchMark) {
                this.garudaApi.getBenchmark(allProfileIds, this.isGroup, this.highlightedProfile, this.hiddenProfiles).then((html: string) => {
                    GrafModule.showGraphic(html, graphicsContainerElement);
                    this.afterContentRendered();
                });
            }

            if (this.graphicType === GraphicTypeEnum.consistencyBarGraph) {
                this.garudaApi.getConsistencyBarGraph(this.profileId).then((html: string) => {
                    GrafModule.showGraphic(html, graphicsContainerElement);
                });
            }

            if (this.graphicType === GraphicTypeEnum.consistencyBarGraphWithPanelHeader) {
                this.garudaApi.getConsistencyBarGraphWithHeader(this.profileId).then((html: string) => {
                    GrafModule.showGraphic(html, graphicsContainerElement);
                });
            }

            if (this.graphicType === GraphicTypeEnum.focusModel) {
                this.garudaApi.getFocusModel(this.profileId).then((html: string) => {
                    GrafModule.showGraphic(html, graphicsContainerElement);
                });
            }

            if (this.graphicType === GraphicTypeEnum.miniatureFocusModel) {
                this.garudaApi.getMiniatureFocusModel(this.profileId).then((html: string) => {
                    GrafModule.showGraphic(html, graphicsContainerElement);
                });
            }

            if (this.graphicType === GraphicTypeEnum.miniatureFocusModelWithHeader) {
                this.garudaApi.getMiniatureFocusModelWithHeader(this.profileId).then((html: string) => {
                    GrafModule.showGraphic(html, graphicsContainerElement);
                });
            }

            if (this.graphicType === GraphicTypeEnum.miniaturePressMatrixlWithHeader) {
                this.garudaApi.getMiniaturePressMatrixWithHeader(this.profileId).then((html: string) => {
                    GrafModule.showGraphic(html, graphicsContainerElement);
                });
            }

            if(this.graphicType === GraphicTypeEnum.IQTraits) {
                this.garudaApi.getIQTraits(this.profileId,typeId).then((html: string) => {
                    GrafModule.showGraphic(html, graphicsContainerElement);
                });
            }

            if(this.graphicType === GraphicTypeEnum.IQTotals) {
                this.garudaApi.getIQTotal(this.profileId).then((html: string) => {
                    GrafModule.showGraphic(html, graphicsContainerElement);
                });
            }
        })
    }

    private static showGraphic(html: string, graphicsContainerElement: JQuery<HTMLElement>) {
        graphicsContainerElement.siblings(".spinner").hide();
        graphicsContainerElement.find(".spinner").hide();

        graphicsContainerElement.html(html);
        graphicsContainerElement.show();
    }
}



