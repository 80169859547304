import {ProfileSettingComponent} from "./ProfileSettingComponent";
import {ProfileLanguageComponent} from "./ProfileLanguageComponent";
import {ProfilePurposeComponent} from "./ProfilePurposeComponent";
import {ProfileSharingComponent} from "./ProfileSharingComponent";
import {GarudaApi} from "../../../infrastructure/garudaApi";
import {Localization} from "../../../modules/localization-module";
import {CheckLabelComponent} from "../../ui-components/CheckLabelComponent";
import * as React from "react";
import {Analysis, CheckSettingModel, ToolCrudModel} from "../../../models/profileCrud/ToolCrudModel";
import {RespondentModel} from "../../../models/profileCrud/RespondentModel";
import {ProfileAssociationComponent} from "./ProfileAssociationComponent";
import {ProfileSpecialSettingComponent} from "./ProfileSpecialSettingComponent";
import {KeyValueSettingModel} from "../../../models/settings/KeyValueSettingModel";
import {TagModuleComponent} from "../../../modules/tag-module";
import {ProfileAddGarudaLogicComponent} from "./ProfileAddGarudaLogicComponent";
import {
    settingConstant_SelfEvaluation,
    subtoolMirror,
    subtoolMirrorFollowup,
    subtoolPersonal,
    toolGarudaLogic
} from "../../../infrastructure/constants";
import {ToolEnum, ToolEnumToConstantsConverter} from "../../../models/enums/toolEnum";
import {SelectListItem} from "../../ui-components/SelectListItem";
import {SelectListComponent} from "../../ui-components/SelectListComponent";

interface IProfileCreateProps {
    selectTool: (toolId: string) => void;
    selectSubTool: (toolId: string) => void;
    selectLanguage: (purpose: string) => void;
    selectSetting: (settingKey: string, value: string) => void;
    selectSpecialSettings: (settingKey: string, value: string) => void;
    selectProfileAssociation: (settingKey: string, profileId: string, associatedName: string) => void;
    selectGarudaLogicSubtool: (subToolId?: string) => void;
    setValue: (key: string, val: string | string[]) => void;
    getSetting: (settingKey: string) => any;
    selectProfilesharings: () => void;
    toolData: ToolCrudModel;
    respondent: RespondentModel;
}

interface IProfileCreateState {
    availableUserConnections: any[];
}

export class ProfileCreateComponent extends React.Component<IProfileCreateProps, IProfileCreateState> {

    private garudaApi: GarudaApi;
    private availableTools: string[] = [];

    constructor(props: IProfileCreateProps) {
        super(props);

        this.garudaApi = new GarudaApi();

        this.state = {
            availableUserConnections: null
        }
    }

    private renderTools(): JSX.Element[] {
        const availableToolItems = [];

        for (const tool of this.props.toolData.tools) {
            this.availableTools.push(tool.toolId);
            availableToolItems.push(<SelectListItem selectedValue={this.props.respondent.selectedToolId}
                                                    onClick={() => this.props.selectTool(tool.toolId)} label={tool.name}
                                                    key={`item-${tool.toolId}`} value={tool.toolId}/>);
        }

        return availableToolItems;
    }

    private renderSubtools(): JSX.Element[] {
        const availableSubtoolItems = [];

        const selectedToolId = this.props.respondent.selectedToolId;
        const availableSubtools = this.props.toolData.tools.find(t => t.toolId === selectedToolId).analysis;

        for (const subTool of availableSubtools) {
            availableSubtoolItems.push(<SelectListItem selectedValue={this.props.respondent.selectedSubtoolId}
                                                       onClick={() => this.props.selectSubTool(subTool.toolSubId)}
                                                       label={subTool.name}
                                                       key={`subtool-${subTool.toolSubId}`}
                                                       value={subTool.toolSubId}/>);
        }

        return availableSubtoolItems;
    }

    private renderSettings() {
        const list: JSX.Element[] = [];

        const selectedSubtool: Analysis = this.props.toolData.SelectedSubTool(this.props.respondent.selectedToolId, this.props.respondent.selectedSubtoolId);
        const questionarySettings = selectedSubtool ? selectedSubtool.questionnaireSettings : this.props.toolData.AllQuestionarySettings();

        if (questionarySettings?.length > 0) {
            list.push(
                <div key={`setting-${list.length}`} className="col-md-12">
                    <h5>{Localization.getText("Profile_Settings")}</h5>

                    { ToolEnumToConstantsConverter.Convert(this.props.respondent.selectedToolId) == ToolEnum.KompetenceProfile &&
                        <div
                            className="subtle margin-bottom-m">{Localization.getText("Respondent_Create_SettingsMessage")}</div>
                    }

                    <div className="row">
                        {
                            questionarySettings.map((checkLabel: CheckSettingModel, idx: number) => {
                                const value = this.props.respondent.settingValues.find(sv => sv.key == checkLabel.key).isChecked();
                                if(checkLabel.key ===  settingConstant_SelfEvaluation) {
                                    return (
                                        <div className={'margin-top-s'}>
                                            <CheckLabelComponent key={`checklabel-${idx}`}
                                                                 disabled={false}
                                                                 label={checkLabel.label}
                                                                 value={value}
                                                                 settingkey={checkLabel.key}
                                                                 licensed={checkLabel.userHasLicense}
                                                                 onSelected={(e) => this.props.selectSetting(checkLabel.key, e)}/>
                                        </div>
                                    )
                                }else {
                                    return (
                                        <CheckLabelComponent key={`checklabel-${idx}`}
                                                             disabled={false}
                                                             label={checkLabel.label}
                                                             value={value}
                                                             settingkey={checkLabel.key}
                                                             licensed={checkLabel.userHasLicense}
                                                             onSelected={(e) => this.props.selectSetting(checkLabel.key, e)}/>);
                                }
                            })}
                    </div>
                </div>);
        }

        return list;
    }

    private renderSpecialSettings() {
        const selectedSubtool: Analysis = this.props.toolData.SelectedSubTool(this.props.respondent.selectedToolId, this.props.respondent.selectedSubtoolId);
        const specialSettings = selectedSubtool ? selectedSubtool.specialSetting as KeyValueSettingModel[] : [];

        if (specialSettings?.length > 0) {
            return (<>
                <h5 className="col-sm-12">{Localization.getText("Profile_TitleAndFunction")}</h5>
                <ProfileSpecialSettingComponent respondent={this.props.respondent}
                                                toolData={this.props.toolData}
                                                setSpecialSetting={this.props.selectSpecialSettings}/>
            </>);
        }
    }

    private renderQuestionnaireTags() {

        return (<div className="col-sm-12">
            <div className="form-group">
                <label>{Localization.getText("Questionary_Tags")}</label>
                <TagModuleComponent
                    select2Id={"questionary-tags-select2"}
                    options={this.props.respondent.questionnaireTags}
                    addTags={(selectedTags) => this.onAddTagItems(selectedTags)}
                    removeTags={() => null}/>
            </div>
        </div>);
    }

    private onAddTagItems(selectedQuestionnaireTags: string[]) {
        this.setValue("selectedQuestionnaireTags", selectedQuestionnaireTags);
    }

    private setValue(key: string, val: string | string[]) {
        this.props.setValue(key, val);
    }

    public componentDidMount() {
        if (!this.props.respondent.selectedToolId) {
            this.props.selectTool(this.availableTools[0]);
        }
    }

    public render() {
        return (
            <>
                <div className="row ">
                    <div className="col-sm-12">
                        <div className={"row"}>
                            <div className={"col-md-4"}>
                                <SelectListComponent title={Localization.getText("PersonalProfile_Respondent_Tool")}
                                                     name={"SelectedToolId"} onChange={(e) => this.props.selectTool(e)}>
                                    {this.renderTools()}
                                </SelectListComponent>
                            </div>
                            <div className="col-md-4">
                                <SelectListComponent title={Localization.getText("PersonalProfile_Respondent_SubTool")}
                                                     onChange={(e) => this.props.selectSubTool(e)}>
                                    {this.props.respondent.selectedToolId &&
                                    this.renderSubtools()
                                    }
                                </SelectListComponent>
                            </div>
                            <div className="col-md-4">
                                <SelectListComponent title={Localization.getText("GENERAL_LANGUAGE")}
                                                     onChange={(e) => this.props.selectLanguage(e)}>
                                    {this.props.respondent.selectedToolId && this.props.respondent.selectedSubtoolId &&
                                    <ProfileLanguageComponent respondent={this.props.respondent}
                                                              toolData={this.props.toolData}
                                                              selectLanguage={this.props.selectLanguage}
                                                              dropdown={false}/>
                                    }
                                </SelectListComponent>
                            </div>
                        </div>

                        {this.props.respondent.selectedSubtoolId &&
                        <div>
                          <div className={"row margin-top-m"}>
                              {this.renderSpecialSettings()}
                          </div>
                            {this.props.respondent.selectedToolId !== toolGarudaLogic &&
                            this.props.respondent.selectedSubtoolId === subtoolPersonal &&
                                <div className={"row margin-top-m"}>
                                    <ProfileAddGarudaLogicComponent
                                        respondent={this.props.respondent}
                                        toolData={this.props.toolData}
                                        selectGarudaLogicSubtool={this.props.selectGarudaLogicSubtool}
                                        hasLicenceForGarudaLogic={true}
                                    />
                                </div>
                            }
                            {(this.props.respondent.selectedSubtoolId === subtoolMirror ||
                                this.props.respondent.selectedSubtoolId === subtoolMirrorFollowup) &&
                            <div className="row margin-top-m">
                              <ProfileAssociationComponent respondent={this.props.respondent}
                                                           selectSetting={this.props.selectSetting}
                                                           getSetting={this.props.getSetting}
                                                           selectProfileAssociation={(k: string, v: string, name: string) => this.props.selectProfileAssociation(k, v, name)}
                                                           toolIdentifier={this.props.respondent.selectedToolId}
                                                           subToolIdentifier={this.props.respondent.selectedSubtoolId}/>
                            </div>
                            }
                          <div className="row margin-top-m">
                            <div className="col-sm-12">
                              <ProfilePurposeComponent respondent={this.props.respondent}
                                                       selectSetting={this.props.selectSetting}
                                                       getSetting={this.props.getSetting}
                                                       selectSpecialSettings={this.props.selectSpecialSettings}
                                                       toolData={this.props.toolData}/>
                            </div>
                          </div>
                          <div className="row margin-top-m">
                              {this.renderQuestionnaireTags()}
                          </div>
                          <div className="row margin-top-m">
                              {this.renderSettings()}
                          </div>
                          <div className={"row margin-top-m"}>
                            <ProfileSettingComponent respondent={this.props.respondent}
                                                     selectSetting={this.props.selectSetting}
                                                     toolData={this.props.toolData}/>
                          </div>
                          <div className="row margin-top-m">
                            <div className="col-sm-12">
                              <ProfileSharingComponent respondent={this.props.respondent}
                                                       selectProfilesharings={this.props.selectProfilesharings}/>
                            </div>
                          </div>
                        </div>
                        }

                    </div>
                </div>
            </>);
    }
}
