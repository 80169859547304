import {GarudaApi} from "./infrastructure/garudaApi";
import {TableModule} from "ditmer-embla";
import {Localization} from "./modules/localization-module";
import {Modal} from "./modules/modal-module";

export class EventLogModule {
    private readonly garudaApi: GarudaApi;
    private readonly id: string; // Can be both respondent and profileId depending on the context
    private readonly isProfile: boolean;
    private table: DataTables.Api;
    private modal: Modal;

    private readonly eventLogContainerSelector: string = "#eventlog-container";
    private readonly spinnerSelector: string = "#eventlog-spinner";
    private readonly tableSelector: string = ".eventlog-table";
    private readonly titleSelector: string = "#eventlog-title-container";
    private readonly modalSelector: string = "#eventlog-modal";
    private readonly modalTriggerSelector: string = "#eventlog-modal-button";

    constructor(id: string, isProfile: boolean = true) {
        this.garudaApi = new GarudaApi();
        this.id = id;
        this.isProfile = isProfile;
    }

    private showTable() {
        $(this.spinnerSelector).hide();
        $(this.eventLogContainerSelector).css("opacity", 1);
    }

    private hideTable() {
        $(this.spinnerSelector).show();
        $(this.eventLogContainerSelector).css("opacity", 0);
    }

    public async initTable() {
        const eventLog = $(this.eventLogContainerSelector);

        // If no eventlog table is present on the page, return
        if (eventLog.length === 0) {return;}

        this.hideTable();

        const eventLogHtml = await this.garudaApi.getEventLog(this.id, true, false);
        eventLog.html(eventLogHtml)

        if (this.table) {
            this.table.destroy();
        }

        const tableModule = this.createTableModule(this.tableSelector, this.titleSelector);

        this.table = tableModule.getDatatablesApi();

        this.showTable();
    }

    private createTableModule(tableSelector: string, titleSelector?: string) {
        return new TableModule({
            tableSelector: tableSelector,
            titleSelector: titleSelector,
            useSelect2: false,
            responsive: true,
            paging: false,
            additionalDatatableSettings: {
                searching: false,
                ordering: false
            },
            language: {
                zeroRecords: Localization.getText("NoProfileFound"),
            },
        });
    }

    public async initModal() {
        this.modal = new Modal(
            this.modalTriggerSelector,
            this.modalSelector,
            () => this.garudaApi.getEventLog(this.id, this.isProfile, true),
            () => {});

        this.modal.init();
    }

    public async openModal() {
        await this.modal.loadModalContent();
        this.modal.open()
    }
}
