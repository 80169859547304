import * as React from "react";
import {
    AttachedProfileId,
    settingsConstantsProfileAssociation, subtoolMirror,
    subtoolMirrorFollowup
} from "../../../infrastructure/constants";
import {PersonProfileModel} from "../../../models/profileCrud/PersonProfileModel";
import {Localization} from "../../../modules/localization-module";
import {RespondentModel} from "../../../models/profileCrud/RespondentModel";
import {GarudaApi} from "../../../infrastructure/garudaApi";
import {ChangeEvent} from "react";
import {DropdownModule} from "ditmer-embla";
import {SpinnerComponent} from "../../ui-components/SpinnerComponent";

interface IProfileAssociationProps {
    respondent: RespondentModel;
    selectSetting: (settingKey: string, value: string) => void;
    getSetting: (settingKey: string) => any;
    selectProfileAssociation: (key: string, value: string, name: string) => void;
    toolIdentifier: string;
    subToolIdentifier: string;
}

interface IProfileAssociationState {
    availablePersonalProfiles: PersonProfileModel[];
}

export class ProfileAssociationComponent extends React.Component<IProfileAssociationProps, IProfileAssociationState> {
    private garudaApi: GarudaApi;

    constructor(props: IProfileAssociationProps) {
        super(props);

        this.garudaApi = new GarudaApi();

        this.state = {
            availablePersonalProfiles: null
        }
    }

    private static formatResult(state: any) {
        if (!state.id) {
            return state.name || state.text;
        }

        const email = state.element.dataset.email;

        return $(`<span>${state.text}</span> <span class="dropdown-item-grey">${email}</span>`);
    }

    private static formatSelection(selection: any) {
        if (!selection.id) {
            return selection.name || selection.text;
        }

        const email = selection.element.dataset.email;
        const text = selection.text;

        return $(`<span>${text}</span> <span class="dropdown-item-grey">${email}</span>`);
    }

    public async componentDidMount() {
        // Note: This does not reload the list if the subtool is switched from Mirror to MirrorFollowUp
        if (this.state.availablePersonalProfiles === null) {
            const personProfiles = await this.garudaApi.getPersonprofileListJson(this.props.toolIdentifier, this.props.subToolIdentifier);

            this.setState({
                availablePersonalProfiles: personProfiles.value
            });
        }

        const select2 = $("#personal-profiles");
        const obj = this.props.respondent.specialSettingValues.find(x=>x.key == AttachedProfileId );
        if(obj != null) {
            select2.val(obj.value);
            select2.trigger("select2:select");
        }
    }

    public async componentDidUpdate(prevProps: Readonly<IProfileAssociationProps>, prevState: Readonly<IProfileAssociationState>) {
        const select2 = $("#personal-profiles");
        new DropdownModule().init(select2.get(0), {
            placeholder: Localization.getText("Global_Select"),
            additionalSelect2Options: {
                templateResult: (result) => ProfileAssociationComponent.formatResult(result),
                templateSelection: (selection) => ProfileAssociationComponent.formatSelection(selection),
                language: { noResults: function() { return Localization.getText("Global_NoResult"); }}
        }});

        // @ts-ignore
        select2.on("select2:unselect", (e: ChangeEvent<HTMLOptionElement>) => {
            const value = e.currentTarget.value;
            this.props.selectProfileAssociation(AttachedProfileId, value, null);

            const key = $(e.currentTarget).data("key") as string;
            this.props.selectSetting(key, null);
        });

        // @ts-ignore
        select2.on("select2:select", (e: ChangeEvent<HTMLOptionElement>) => {
            const value = e.currentTarget.value;
            const key = $(e.currentTarget).data("key") as string;

            if (value == undefined) {
                this.props.selectProfileAssociation(AttachedProfileId, null, null);
                this.props.selectSetting(key, null);
                return;
            }

            const name = this.state.availablePersonalProfiles.find(op => op.id === value).name;
            this.props.selectProfileAssociation(AttachedProfileId, value, name);

            this.props.selectSetting(key, value);
        });
    }

    public render() {
        let dropdownItems;
        if (this.state.availablePersonalProfiles !== null) {
            dropdownItems = this.state.availablePersonalProfiles.map((item: PersonProfileModel) => {
                return (<option value={item.id} key={item.id} data-email={item.email}>{item.name}</option>);
            });
        }

        return (
            <>
                <div className={"col-sm-12"}>
                    <label className="bold" htmlFor={"personal-profiles"}>
                        {Localization.getText("PersonalProfile_Assign_PersonProfile")}

                        {this.props.subToolIdentifier === subtoolMirror  &&
                            <span className="required-field">∗</span>
                        }

                        {this.props.subToolIdentifier === subtoolMirrorFollowup  &&
                            <span className="required-field">∗</span>
                        }
                    </label>
                    {dropdownItems &&
                    <select data-key={"PROFILE_ASSOCIATION"} id={"personal-profiles"} defaultValue={""} className={"form-control"}
                            name={"profile-association-select"}>
                        <option/>
                        {dropdownItems}
                    </select>
                    }
                    {!dropdownItems &&
                    <SpinnerComponent small={true} />
                    }
                </div>
            </>
        );
    }
}
