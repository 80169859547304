import { MergeFieldModel } from "./MergeFieldModel";

export class TemplateListModel {
    templateId: number;
    smsTemplateId : number;
    displayName: string;
    subject: string;
    templateBody: string;
    mergeFields: MergeFieldModel[];
}
