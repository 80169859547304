import * as React from "react";
import {GarudaApi} from "../../infrastructure/garudaApi";
import {SpinnerComponent} from "../ui-components/SpinnerComponent";
import {TagSharingComponent} from "../ui-components/TagSharingComponent";
import {Localization} from "../../modules/localization-module";
import {TagModuleComponent} from "../../modules/tag-module";
import {SelectItemModel} from "../../models/SelectItemModel";
import {MultiRespondentEditModel} from "../../models/tags/MultiRespondentEditModel";
import {TagEnum} from "../../models/enums/TagEnum";
import {TagsModel} from "../../models/tags/TagsModel";
import {Select2Component} from "../ui-components/Select2Component";
import {DatePickerComponent} from "../ui-components/DatePickerComponent";
import {DatetimePickerTypeEnum} from "ditmer-embla";
import {SubToolEnum} from "../../models/enums/subToolEnum";
import {RespondentCrudHelper} from "../../models/profileCrud/RespondentCrudHelper";

interface RespondentsTagsEditProps {
    saveCallbackButtonSelector?: string;
    modalSelector?: string;
    saveCallback?: () => void;
    saveCallbackValidation?: (error: string) => void;
    multiRespondentEditModel: MultiRespondentEditModel;
    showProfileTags: boolean
    subtool: SubToolEnum
}

interface RespondentsTagsEditState {
    isLoading: boolean;
    isSelectInitialized: boolean;
    noOptions: string[];
    respondentTypeEnumModels: SelectItemModel[];
    respondentTagsSelectItems: SelectItemModel[];
    profileTagsSelectItems: SelectItemModel[];
    existingMultiRespondentEditModel: MultiRespondentEditModel;
    multiRespondentsToEdit: MultiRespondentEditModel;
}

export class EditRespondentsTagsApp extends React.Component<RespondentsTagsEditProps, RespondentsTagsEditState> {

    private readonly garudaApi: GarudaApi;
    private readonly respondentCrudHelper : RespondentCrudHelper;

    constructor(props: RespondentsTagsEditProps) {
        super(props);

        this.garudaApi = new GarudaApi();
        this.respondentCrudHelper = new RespondentCrudHelper();
        this.state = {
            noOptions: [],
            isLoading: true,
            isSelectInitialized: false,
            respondentTypeEnumModels: [],
            respondentTagsSelectItems: [],
            profileTagsSelectItems: [],
            existingMultiRespondentEditModel: this.props.multiRespondentEditModel.copy(),
            multiRespondentsToEdit: this.props.multiRespondentEditModel.copy()
        };
    }

    private setExpiryDate(value: string) {
        this.state.multiRespondentsToEdit.respondentExpirationDate = value;
        this.setState(this.state);
    }

    private setRespondentType(value: number) {
        this.state.multiRespondentsToEdit.respondentType = value;
        this.setState(this.state);
    }

    private async saveRespondentsTags() {
        await this.garudaApi.updateRespondentsTags(this.state.multiRespondentsToEdit);
    }

    private removeTagFromList(newTagsList: TagsModel[],
                              existingTagsList: TagsModel[],
                              tagSelectItems: SelectItemModel[],
                              tagToRemove: string) {

        newTagsList.forEach(rt => {
            rt.tags = rt.tags.filter(value => value !== tagToRemove);
        });

        existingTagsList.forEach(rt => {
            rt.tags = rt.tags.filter(value => value !== tagToRemove);
        });

        if (tagSelectItems.every(ts => ts.value !== tagToRemove)) {
            tagSelectItems.push({value: tagToRemove, label: tagToRemove, selected: false});
        }
    }

    private removeTags(tagsToRemove: string[],
                       respondentTag: boolean) {
        tagsToRemove.forEach(tagToRemove => {
            if (respondentTag) {
                this.removeTagFromList(this.state.multiRespondentsToEdit.respondentTags,
                    this.state.existingMultiRespondentEditModel.respondentTags,
                    this.state.respondentTagsSelectItems,
                    tagToRemove
                );
            } else {
                this.removeTagFromList(this.state.multiRespondentsToEdit.profileTags,
                    this.state.existingMultiRespondentEditModel.profileTags,
                    this.state.profileTagsSelectItems,
                    tagToRemove
                );
            }
        });

        this.setState(this.state);
    }

    private addTagToList(tags: TagsModel[],
                         tagToAdd: string) {

        tags.forEach(rt => {
            if (!rt.tags.some(value => value === tagToAdd)) {
                rt.tags.push(tagToAdd);
            }
        });
    }

    private addTags(tagsToAdd: string[],
                    respondentTag: boolean,
                    addToExistingTagList: boolean) {
        tagsToAdd.forEach(tagToAdd => {
            if (respondentTag) {
                this.addTagToList(this.state.multiRespondentsToEdit.respondentTags, tagToAdd);

                if (addToExistingTagList) {
                    this.addTagToList(this.state.existingMultiRespondentEditModel.respondentTags, tagToAdd);
                }
            } else {
                this.addTagToList(this.state.multiRespondentsToEdit.profileTags, tagToAdd);

                if (addToExistingTagList) {
                    this.addTagToList(this.state.existingMultiRespondentEditModel.profileTags, tagToAdd);
                }
            }
        });

        this.setState(this.state);
    }

    public async componentDidMount() {
        const selectItem = new SelectItemModel();
        selectItem.value = null;
        selectItem.label = Localization.getText("Global_Select");
        selectItem.selected = true;
        const respondentTypeEnumModels: SelectItemModel[] = [selectItem];

        const toolData = await this.garudaApi.getTools(null);
        respondentTypeEnumModels.push(...toolData.respondentTypeEnumModels);

        const allrespondentTags = this.state.existingMultiRespondentEditModel.respondentTags.selectMany(rt => rt.tags);
        let respondentTagsSelectItems = await this.garudaApi.getCompanyTags(TagEnum.RespondentTag);
        respondentTagsSelectItems = respondentTagsSelectItems.filter(ct => allrespondentTags.every(x => x !== ct.value));

        const allprofileTags = this.state.existingMultiRespondentEditModel.profileTags.selectMany(rt => rt.tags);
        let profileTagsSelectItems = await this.garudaApi.getCompanyTags(TagEnum.ProfileTag);
        profileTagsSelectItems = profileTagsSelectItems.filter(ct => allprofileTags.every(x => x !== ct.value));

        const $this = this;
        $(this.props.modalSelector).find(this.props.saveCallbackButtonSelector).off("click").on("click", async function () {
            await $this.saveRespondentsTags();
            $this.props.saveCallback();
        });

        this.setState({
            isLoading: false,
            respondentTypeEnumModels: respondentTypeEnumModels,
            respondentTagsSelectItems: respondentTagsSelectItems,
            profileTagsSelectItems: profileTagsSelectItems
        });
    }

    public componentDidUpdate() {
        if (this.state.isSelectInitialized === false && this.state.isLoading === false) {

            this.setState({
                isSelectInitialized: true
            });
        }
    }

    public render() {
        if (this.state.isLoading) {
            return (<SpinnerComponent/>);
        }

        return (
            <div className="respondents-tags-edit">
                <div className="row">
                    <div className="col-md-12">
                        <h4 className="margin-bottom-m">{Localization.getText("ERESPONDENT")}</h4>

                        {this.state.respondentTypeEnumModels &&
                         this.respondentCrudHelper.canSetRespondentType(this.props.subtool) &&
                        <div>
                            <div className="form-group">
                                <div className="form-group">
                                    <label htmlFor="Tags">{Localization.getText("Respondent_Type")}</label>
                                    <Select2Component multiple={false}
                                                      options={this.state.respondentTypeEnumModels}
                                                      select2Id="respondent-type-select"
                                                      itemSelected={(value) => this.setRespondentType(parseInt(value))}
                                                      itemUnselected={() => null}
                                                      additionalSelect2Options={{tags: true}}
                                                      disabled={!this.props.multiRespondentEditModel.allRespondentsIsOwned}
                                    />
                                </div>
                            </div>
                        </div>
                        }

                        { this.respondentCrudHelper.canSetExpiryDate(this.props.subtool) &&
                        <div>
                            <div className="form-group">
                                <DatePickerComponent
                                    calenderType={DatetimePickerTypeEnum.Date}
                                    label={Localization.getText("Global_Expiration")}
                                    disabled={!this.props.multiRespondentEditModel.allRespondentsIsOwned}
                                    dateSelected={(key, value) => this.setExpiryDate(value)}
                                    settingkey={"expiry-date"}
                                    value={this.state.existingMultiRespondentEditModel.respondentExpirationDate}
                                    allowClear={true}/>
                            </div>
                        </div>
                        }

                        <div className="form-group">
                            <label>{Localization.getText("Respondent_Tags")}</label>
                            <TagModuleComponent
                                select2Id={"respondents-tags-select2"}
                                options={this.state.respondentTagsSelectItems}
                                removeTags={(tags) => this.removeTags(tags, true)}
                                addTags={(tags) => this.addTags(tags, true, false)}/>
                        </div>
                        <div className={"margin-bottom-s"}>
                            <TagSharingComponent
                                respondentTags={true}
                                tagModels={this.state.existingMultiRespondentEditModel.respondentTags}
                                removeTags={(tags) => this.removeTags(tags, true)}
                                addTags={(tags) => this.addTags(tags, true, true)}/>
                        </div>
                    </div>
                </div>

                {this.props.showProfileTags &&
                <div className="row margin-top-l">
                    <div className="col-md-12">
                        <h4 className="margin-bottom-m">{Localization.getText("Global_Questionary")}</h4>
                        <div className="form-group">
                            <label>{Localization.getText("Questionary_Tags")}</label>
                            <TagModuleComponent
                                select2Id={"profile-tags-select2"}
                                options={this.state.profileTagsSelectItems}
                                removeTags={(tags) => this.removeTags(tags, false)}
                                addTags={(tags) => this.addTags(tags, false, false)}/>
                        </div>
                        <div className={"margin-bottom-s"}>
                            <TagSharingComponent
                                respondentTags={false}
                                tagModels={this.state.existingMultiRespondentEditModel.profileTags}
                                removeTags={(tags) => this.removeTags(tags, false)}
                                addTags={(tags) => this.addTags(tags, false, true)}/>
                        </div>
                    </div>
                </div>
                }
            </div>
        );
    }
}
