import {GroupProfileComponent} from "./react/groupprofile-components/GroupProfileComponent";
import {renderComponent} from "./react/react-helper";
import * as React from "react";
import { GroupProfileTypeEnum } from "./models/enums/groupProfileTypeEnum";
import GarudaApi = require("./infrastructure/garudaApi");


export const initializeCreateStaticGroupProfileReactApp = (profileIds: string[],
    modalSelector: JQuery<HTMLElement>,
    saveButtonSelector: string,
    saveCallback: (parameter: any) => void) => {

    const reactContainer = document.getElementById("groupprofile-app-container");
    const component = <GroupProfileComponent
        saveCallback={(parameter) => saveCallback(parameter)}
        saveButtonSelector={saveButtonSelector}
        type={GroupProfileTypeEnum.Statisk}
        selectedProfiles={profileIds}
        modalSelector={modalSelector}    />;

    renderComponent(component, reactContainer);
}
