export class NoteModel {

    constructor(threadId: string, noteId: string, noteBody: string, viewType: string) {
        this.threadId = threadId;
        this.noteId = noteId;
        this.noteBody = noteBody;
        this.viewType = viewType;
    }

    threadId: string;
    noteId: string;
    noteBody: string;
    viewType: string;
    isPrivate: string;
}
