import { DropdownModule, NotificationModule } from "ditmer-embla";
import { GarudaApi } from "../../infrastructure/garudaApi";
import { Spinner } from "../spinner-module";
import { StateStorage } from "../../infrastructure/stateStorage";
import { SelectedProfileModel } from "../../models/SelectedProfileModel";
import { currentAttachedProfilesStorageKey } from "../../infrastructure/constants";
import { Localization } from "../localization-module";
import {SubToolEnum} from "../../models/enums/subToolEnum";
import {ToolEnum} from "../../models/enums/toolEnum";

export class ProfileCompareWizard {
    private readonly garudaApi: GarudaApi;
    private readonly spinnerController: Spinner;
    private readonly stateStorage: StateStorage;
    private readonly personProfileId: string;
    private readonly applyCallback: () => void;

    private readonly modalButtonSelector = $("#show-compare-modal");
    private readonly modalSelector = $("#modal-personprofil-compare");
    private readonly modalTitleSelector = $("#modal-personprofil-compare-header");
    private readonly modalApplySelector = $("#modal-btn-update-attached-profiles");

    private readonly modalContainerSelector = "#modal-personprofil-container";
    private readonly selectionContainerSelectorPrefix = "#previous-selection-container-";
    private readonly listItemSelector = ".list-item";
    private readonly subtoolIdDataAttr = "sub-tool-id";
    private readonly multiselectDataAttr = "allows-multiselect";
    private readonly removePossibleProfilesButtonSelector = ".remove-attached-profile";
    private readonly possibleCompareProfileDropwdown = "#add-profile-attached";
    private readonly tagDropwdown = "#tagFilter";
    private readonly subtoolsToExclude : SubToolEnum[];
    private readonly profileSubtoolEnum : SubToolEnum;
    private readonly currentTool : ToolEnum;
    private readonly includeGroup : boolean;

    private selectedSubtoolId: string;
    private selectedSubtoolName: string;
    private notAppliedSelectedProfiles: SelectedProfileModel[];
    private readonly attachedProfilesStorageKey: string;

    constructor(personProfileId: string,
                applyCallback: () => void,
                profileSubtoolEnum : SubToolEnum,
                currentTool : ToolEnum,
                subtoolsToExclude : SubToolEnum[] = null,
                includeGroup: boolean = false) {
        this.garudaApi = new GarudaApi();
        this.spinnerController = new Spinner("#compare-modal-spinner", this.modalContainerSelector);
        this.stateStorage = new StateStorage();
        this.attachedProfilesStorageKey = `${currentAttachedProfilesStorageKey}${personProfileId}`;

        this.personProfileId = personProfileId;
        this.applyCallback = applyCallback;
        this.subtoolsToExclude = subtoolsToExclude;
        this.profileSubtoolEnum = profileSubtoolEnum;
        this.currentTool = currentTool;
        this.includeGroup = includeGroup;

        const $this = this;
        this.modalButtonSelector.off("click").on("click", async () => {
            await $this.showCompareModal();
        });
    }

    public async showCompareModal(resetNotAppliedSelectedProfiles: boolean = true) {
        if (resetNotAppliedSelectedProfiles) {
            this.notAppliedSelectedProfiles = this.stateStorage.load<SelectedProfileModel[]>(`${this.attachedProfilesStorageKey}`) ?? [];
        }

        this.modalSelector.modal("show");
        $(this.modalSelector).find("#modal-reset").children("svg").hide();
        this.spinnerController.showSpinner();

        this.setModalTitle(Localization.getText("PersonalProfile_CompareHeader"));
        const modalBodyHtml = await this.garudaApi.getCompareModalBody(this.subtoolsToExclude, this.currentTool, this.includeGroup);

        this.spinnerController.hideSpinner();
        $(this.modalContainerSelector).html(modalBodyHtml);

        this.initProfileList();
    }

    private initProfileList(): void {

        const $this = this;

        // clickevent on apply
        $(this.modalApplySelector).off("click").on("click", () => {
            $this.saveAllSelectedProfiles();
            this.applyCallback();
        });

        // clickevent on subtool
        $(this.listItemSelector).off("click").on("click", async (e) => {
            this.selectedSubtoolId = e.currentTarget.dataset.subToolId.toString();
            this.selectedSubtoolName = e.currentTarget.dataset.toolName;
            await $this.showAttachedProfileList();
        });

        // Set selected profiles on each subtool
        $(this.listItemSelector).each((idx, elem) => {
            const toolId = $(elem).data(this.subtoolIdDataAttr);
            this.showAllSelectedProfiles(toolId.toString());
        });
    }

    private showAllSelectedProfiles(subtoolId: string) {
        const allSelectedProfiles = this.getAllSelectedProfiles(subtoolId);

        let htmlString = "";
        for (const selectedProfile of allSelectedProfiles) {
            htmlString += `<p>${selectedProfile.profileName}</p>`;
        }

        $(this.selectionContainerSelectorPrefix + subtoolId).html(htmlString);
    }

    private getAllSelectedProfiles(subtoolId: string) {
        const notAppliedSelectedProfiles = this.notAppliedSelectedProfiles?.filter(profile => profile.subtoolId === subtoolId) ?? [];
        return notAppliedSelectedProfiles;
    }

    private saveAllSelectedProfiles() {
        this.stateStorage.save(`${this.attachedProfilesStorageKey}`, this.notAppliedSelectedProfiles);
    }

    private removeNotAppliedSelectedProfile(profileId: string, self: this) {
        if (profileId) {
            const notCheckedIndex = self.notAppliedSelectedProfiles.findIndex(profile => profile.profileId === profileId);
            if(notCheckedIndex === -1) return;
            this.notAppliedSelectedProfiles.splice(notCheckedIndex, 1);
        } else {
            this.notAppliedSelectedProfiles = this.notAppliedSelectedProfiles.filter(profile => profile.subtoolId !== self.selectedSubtoolId);
        }
    }

    private async showAttachedProfileList() {

        const $this = this;

        $(this.modalSelector).find("#modal-reset").children("svg").show();


        this.spinnerController.showSpinner();

        $this.setModalTitle(this.selectedSubtoolName);
        const modalBodyHtml = await this.garudaApi.getProfilesAttached(this.personProfileId, this.profileSubtoolEnum, this.selectedSubtoolId, this.selectedSubtoolName);

        this.spinnerController.hideSpinner();
        $(this.modalContainerSelector).html(modalBodyHtml);

        this.initAttachedprofileList();

        $(this.modalSelector).find("#modal-reset").on("click", () => {

            this.showCompareModal(false);
        });
    }

    private loadall(self: this) {
        $(self.listItemSelector).find("input[type='checkbox']").each((index, element) => {
            const compareProfileId = $(element).data("profile-id");
            const isChecked = this.notAppliedSelectedProfiles?.find(profile => profile.profileId === compareProfileId);

            if (isChecked) {
                $(element).prop("checked", true);
            } else {
                $(element).prop("checked", false);
            }
        });
    }

    private initAttachedprofileList(): void {
        const $this = this;

        this.loadall($this);

        // remove possible selectable compare-profile
        $(this.removePossibleProfilesButtonSelector).off("click").on("click", async (e) => {
            const profileId = e.currentTarget.dataset.idAttached;
            const profilesToRemove: string[] = [profileId];

            await this.garudaApi.deleteAttachedProfile($this.personProfileId, profilesToRemove);
            $this.removeNotAppliedSelectedProfile(profileId, $this);

            $this.saveAllSelectedProfiles();
            this.applyCallback();

            await this.showAttachedProfileList();
        });

        // check/uncheck compare-profile
        $(this.listItemSelector).off("click").on("click", function (event) {
            if (event.target.tagName === "INPUT") {

                const checkedItem = $(this).find("input[type='checkbox']").first();
                const profileId = checkedItem.data("profile-id");

                if (checkedItem.is(":checked")) {
                    const allowMultiSeelect = $(this).data($this.multiselectDataAttr) !== "False";
                    if (allowMultiSeelect === false) {
                        $this.removeNotAppliedSelectedProfile(null, $this);
                    }

                    $this.addNewSelectedProfile(profileId, checkedItem.data("profile-name"));
                    $this.loadall($this);
                } else {
                    $this.removeNotAppliedSelectedProfile(profileId, $this);
                }
            }
        });

        // possible compare-profiles drop down
        new DropdownModule().init(this.possibleCompareProfileDropwdown);
        new DropdownModule().init(this.tagDropwdown);
    
        $(this.tagDropwdown).off("select2:select").on("select2:select", async () => {
            const selectedOptionText = $($this.tagDropwdown).select2('data')[0].text as string;
            const profilesFiltered = await $this.garudaApi.getAttachedProfilesFiltered(this.personProfileId, this.selectedSubtoolId, selectedOptionText);
            $(this.possibleCompareProfileDropwdown).empty();
            $(this.possibleCompareProfileDropwdown).append(new Option());
            for (var i = 0; i < profilesFiltered.length; i++) {
                $(this.possibleCompareProfileDropwdown).append(new Option(profilesFiltered[i].value, profilesFiltered[i].label,false,false));
            }
        });

        $(this.tagDropwdown).off("select2:unselect").on("select2:unselect", async () => {
            $(this.showAttachedProfileList());
        });


        $(this.possibleCompareProfileDropwdown).off("select2:select").on("select2:select", async () => {
            const profileId = $this.personProfileId;
            const selectedProfileId = $($this.possibleCompareProfileDropwdown).val() as string
            const selectedProfileName = $($this.possibleCompareProfileDropwdown).select2('data')[0].text as string
            if (!selectedProfileId) {
                NotificationModule.showError(Localization.getText("Global_Error"), Localization.getText("PersonalProfile_AddAttached"));
                return;
            }

            const profilesToAdd: string[] = [selectedProfileId];

            await $this.garudaApi.addNewAttachedProfile(profileId, profilesToAdd);
            await $this.showAttachedProfileList();
            $this.loadall($this);
        });
    }

    private addNewSelectedProfile(profileId: string, selectedProfileName: string): void {
        const newSelectedProfile = new SelectedProfileModel();
        newSelectedProfile.profileId = profileId;
        newSelectedProfile.isHidden = true;
        newSelectedProfile.profileName = selectedProfileName;
        newSelectedProfile.subtoolId = this.selectedSubtoolId;
        newSelectedProfile.subtoolName = this.selectedSubtoolName;
        this.notAppliedSelectedProfiles.push(newSelectedProfile);
    }

    private setModalTitle(title: string): void {
        this.modalTitleSelector.text(title);
    }
}
