import * as React from "react";
import {RespondentModel} from "../../../models/profileCrud/RespondentModel";
import {ToolConstants} from "../../../models/enums/toolEnum";
import {settingsSelfEvaluation} from "../../../models/constants/settingsConstants";
import {CheckLabelComponent} from "../../ui-components/CheckLabelComponent";
import {Localization} from "../../../modules/localization-module";

interface ISelfEvaluationSelectProps {
    respondent: RespondentModel;
    selectSetting: (settingKey: string, value: string) => void;
}

export class SelfEvaluationSelectComponent extends React.Component<ISelfEvaluationSelectProps> {

    constructor(props: ISelfEvaluationSelectProps) {
        super(props);
    }

    render() {
        const selfEvaluationSetting = this.props.respondent.settingValues.find(s => s.key == settingsSelfEvaluation);

        return (
            <>
                {(this.props.respondent.selectedToolId == ToolConstants.KompetenceProfileId
                        || this.props.respondent.selectedToolId == ToolConstants.FocusProfileId) &&
                    <div key={`selfevaluation-check-setting`} className="col-md-12">
                        <div className="row">
                            <CheckLabelComponent onSelected={(e) => this.props.selectSetting(selfEvaluationSetting.key, e)}
                                                 label={Localization.getText("GENERAL_INCLUDE_SELF_EVALUATION")}
                                                 value={selfEvaluationSetting.isChecked()}
                                                 settingkey={selfEvaluationSetting.key}
                                                 licensed={true}
                                                 disabled={this.props.respondent.isLocked()}
                                                 key={`general-setting-check-label-${selfEvaluationSetting.key}`} />
                        </div>
                    </div>
                }
            </>
        );
    }
}
