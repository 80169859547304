import * as React from "react";

type SelectListItemProps = {
    value: string;
    label: string;
    selectedValue?: string;
    onClick: (item: string) => void;
};

export const SelectListItem = (props: SelectListItemProps) => {

    if (props.selectedValue && props.selectedValue === props.value) {
        return <li className={"selected"} data-seleced={true} data-value={props.value} onClick={() => props.onClick(props.value)}>{props.label}</li>;
    }

    return <li data-value={props.value} onClick={() => props.onClick(props.value)}>{props.label}</li>;
};
