import { ToolCrudModel } from "../../../models/profileCrud/ToolCrudModel";
import { ProfileSettingComponent } from "./ProfileSettingComponent";
import { RespondentModel } from "../../../models/profileCrud/RespondentModel";
import { ProfileSharingComponent } from "./ProfileSharingComponent";
import { GarudaApi } from "../../../infrastructure/garudaApi";
import { Localization } from "../../../modules/localization-module";
import * as React from "react";
import { ProfilePurposeComponent } from "./ProfilePurposeComponent";
import { ProfileLanguageComponent } from "./ProfileLanguageComponent";
import { ProfileSpecialSettingComponent } from "./ProfileSpecialSettingComponent";
import {TagModuleComponent} from "../../../modules/tag-module";
import {SelfEvaluationSelectComponent} from "./SelfEvaluationSelectComponent";

interface IProfileSimpleEditProps {
    selectLanguage: (purpose: string) => void;
    selectSetting: (settingKey: string, value: string) => void;
    getSetting: (settingKey: string) => any;
    selectSpecialSettings: (settingKey: string, value: string) => void;
    selectProfilesharings: () => void;
    toolData: ToolCrudModel;
    respondent: RespondentModel;
    setValue: (key: string, val: string | string[]) => void;
}

interface IProfileSimpleEditState {
}

export class ProfileSimpleEditComponent extends React.Component<IProfileSimpleEditProps, IProfileSimpleEditState> {

    private garudaApi: GarudaApi;

    constructor(props: IProfileSimpleEditProps) {
        super(props);

        this.garudaApi = new GarudaApi();
    }

    private renderQuestionnaireTags() {

        return (
            <div className="col-sm-12">
                <div className="form-group">
                    <label htmlFor="Tags">{Localization.getText("Questionary_Tags")}</label>
                    <TagModuleComponent
                        select2Id={"profile-edit-questionary-tags-select2"}
                        options={this.props.respondent.questionnaireTags}
                        addTags={(selectedTags) => this.onAddTagItems(selectedTags)}
                        removeTags={() => null}/>
                </div>
            </div>
        );
    }

    private onAddTagItems(selectedQuestionnaireTags: string[]) {
        this.setValue("selectedQuestionnaireTags", selectedQuestionnaireTags);
    }

    private setValue(key: string, val: string | string[]) {
        this.props.setValue(key, val);
    }

    public render() {
        return (
            <>
                <div className="row ">
                    <div className="col-sm-12">

                        <div className={"row"}>
                            <ProfileSpecialSettingComponent respondent={this.props.respondent}
                                                            toolData={this.props.toolData}
                                                            setSpecialSetting={this.props.selectSpecialSettings}/>
                        </div>

                        <div className={"row"}>
                            <div className="col-sm-6">
                                <label className="bold"
                                       htmlFor={"purpose"}>{Localization.getText("GENERAL_LANGUAGE")}
                                    <span className="required-field">∗</span>
                                </label>
                                <ProfileLanguageComponent respondent={this.props.respondent}
                                                          toolData={this.props.toolData}
                                                          selectLanguage={this.props.selectLanguage}
                                                          dropdown={true}/>
                            </div>
                            <div className="col-sm-6">
                                <ProfilePurposeComponent toolData={this.props.toolData}
                                                         selectSetting={this.props.selectSetting}
                                                         getSetting={this.props.getSetting}
                                                         selectSpecialSettings={this.props.selectSpecialSettings}
                                                         respondent={this.props.respondent}/>
                            </div>
                        </div>
                        <div className={"row margin-top-m"}>
                            <ProfileSettingComponent respondent={this.props.respondent}
                                                     selectSetting={this.props.selectSetting}
                                                     toolData={this.props.toolData} />
                            <SelfEvaluationSelectComponent respondent={this.props.respondent}
                                                           selectSetting={this.props.selectSetting}/>
                        </div>
                        <div className={"row margin-top-m"}>
                            {this.renderQuestionnaireTags()}
                        </div>

                        <div className="row margin-top-m">
                            <div className="col-sm-12">
                                <ProfileSharingComponent respondent={ this.props.respondent } selectProfilesharings={ this.props.selectProfilesharings } />
                            </div>
                        </div>
                    </div>
                </div>

            </>);
    }
}
