import * as React from "react";
import { Localization } from "../../../modules/localization-module";
import { GarudaApi } from "../../../infrastructure/garudaApi";
import {RespondentModel} from "../../../models/profileCrud/RespondentModel";
import {ProfileSharingModel} from "../../../models/users/ProfileSharingModel";
import {UserConnectionModel} from "../../../models/users/UserConnectionModel";

interface IProfileSharingProps {
    selectProfilesharings: () => void;
    respondent: RespondentModel;
}

interface IProfileSharingState {
    availableUserConnections: any[];
}

export class ProfileSharingComponent extends React.Component<IProfileSharingProps, IProfileSharingState> {

    private garudaApi: GarudaApi;

    constructor(props: IProfileSharingProps) {
        super(props);

        this.garudaApi = new GarudaApi();

        this.state = {
            availableUserConnections: null
        }
    }

    componentDidMount() {
        this.garudaApi.getUserConnections().then((model: ProfileSharingModel) => {

            const mapUserConnections = model.userConnectionViewModel.map((uc: UserConnectionModel, idx: number) => {
                const selected = this.props.respondent?.profileSharing?.some(p => p == uc.userId);

                if (uc.isConnected) {
                    return (<option key={`${idx}-${uc.userId}`} value={uc.userId} selected={true} data-locked={"locked"}>{uc.name}</option>);
                }

                if (model.useBestFriends && uc.isBestFriend) {
                    return (<option key={`${idx}-${uc.userId}`} value={uc.userId} selected={true}>{uc.name}</option>);
                }

                return (<option key={`${idx}-${uc.userId}`} value={uc.userId} selected={selected}>{uc.name}</option>);
            });

            this.setState({
                availableUserConnections: mapUserConnections
            });
        });
    }

    componentDidUpdate() {
        if (this.state.availableUserConnections) {
            const select2 = $("#available-user-connections").select2({
                placeholder: Localization.getText("EShare"),
                // @ts-ignore
                templateSelection: (tag: any, container: any) => {
                    if (tag.element.dataset.locked === "locked") {
                        $(container).addClass("locked-tag");
                        tag.locked = true;
                    }
                    return tag.text;
                }
            });

            // @ts-ignore
            select2.on("select2:unselecting", (e: any) => {
                // before removing tag we check option element of tag and
                // if it has property 'locked' we will create error to prevent all select2 functionality
                if ($(e.params.args.data.element).attr("locked")) {
                    e.preventDefault();
                }
            });

            // @ts-ignore
            select2.on("select2:unselect", (e: any) => {
                this.props.selectProfilesharings();
            });

            // @ts-ignore
            select2.on("select2:select", (e: any) => {
                this.props.selectProfilesharings();
            });
        }
    }

    render(){
        return (
            <>
                <label htmlFor={"ProfileSharing"}>{Localization.getText("EShare")}</label>
                <select name={"ProfileSharing"} className="form-control" id={"available-user-connections"}
                        multiple={true}>{this.state.availableUserConnections}</select>
            </>);
    }
}
