import * as React from "react";
import {GarudaApi} from "../../../infrastructure/garudaApi";
import {Localization} from "../../../modules/localization-module";
import {DropdownModule} from "ditmer-embla";
import {KeyValueSettingModel} from "../../../models/settings/KeyValueSettingModel";
import {RespondentModel} from "../../../models/profileCrud/RespondentModel";
import {ToolCrudModel} from "../../../models/profileCrud/ToolCrudModel";

interface IPurposeSelectProps {
    respondent: RespondentModel;
    selectSetting: (settingKey: string, value: string) => void;
    getSetting: (settingKey: string) => any;
    selectSpecialSettings: (settingKey: string, value: string) => void;
    toolData: ToolCrudModel;
    onPurposeSelected: (value?:string) => void;
}

export class PurposeSelectComponent extends React.Component<IPurposeSelectProps> {
    private garudaApi: GarudaApi;

    constructor(props: IPurposeSelectProps) {
        super(props);

        this.garudaApi = new GarudaApi();
    }

    public componentDidMount() {
        const select2 = $("#purpose-select");

        new DropdownModule().init(select2.get(0), {
            allowClear: false,
            placeholder: Localization.getText("Global_Select"),
            additionalSelect2Options: {
                language: {
                    noResults: function () {
                        return Localization.getText("Global_NoResult");
                    }
                }
            }
        });

        // @ts-ignore
        select2.on("select2:unselect", (e: any) => {
            const key = $("#purpose-select").data("key") as string;
            this.props.selectSetting(key, null);
            this.props.onPurposeSelected(null);
        });

        // @ts-ignore
        select2.on("select2:select", (e: any) => {
            const value = $("#purpose-select option:selected").val() as string;
            const key = $("#purpose-select").data("key") as string;
            this.props.selectSetting(key, value);
            this.props.onPurposeSelected(value);
        });
    }

    private renderPurposeSelect() {
        const elements: JSX.Element[] = [];

        let purposeSettings: KeyValueSettingModel[] = null;
        if (this.props.respondent.selectedToolId && this.props.respondent.selectedSubtoolId) {
            const subtoolData = this.props.toolData.SelectedSubTool(this.props.respondent.selectedToolId, this.props.respondent.selectedSubtoolId);

            if (subtoolData) {
                purposeSettings = subtoolData.purposeSettings as KeyValueSettingModel[];
            }
        }

        const purposeKey = (purposeSettings && purposeSettings.length > 0) ? purposeSettings[0].key : null;
        const selected = this.props.respondent?.settingValues.find(sv => sv.key === purposeKey)?.value;
        elements.push(
            <select data-key={purposeKey} key={"purpose-select"}
                    name={"purpose-select"} className="form-control"
                    id={"purpose-select"} defaultValue={selected}>
                <option/>
                {purposeSettings?.map((setting: KeyValueSettingModel, idx: number) => {
                    return (<option key={`${setting.key}-${idx}`} value={setting.value}>{`${setting.label}`}</option>);
                })}
            </select>
        );

        return elements;
    }

    public render() {
        return (<>
            {this.renderPurposeSelect()}
        </>);
    }
}
