

interface ITraitNavigatorProps {
    dropdownSelector: string;
    contextMenuButtonSelector: string;
}

export class TraitNavigator {
    private defultDropdownSelector: string = "#select2-Traits-container";
    private contextMenuButtonIdSelector = "#grafContextMenuButtonId";
    private readonly placeholderForContextNavigationBetweenProfiles: string = "__PARAM_VALUE__"

    constructor(props?: ITraitNavigatorProps) {
        if(props) {
            if(props.dropdownSelector)  {
                this.defultDropdownSelector = props.dropdownSelector
            }

            if(props.contextMenuButtonSelector) {
                this.contextMenuButtonIdSelector = props.contextMenuButtonSelector;
            }
        }
    }

    public initTraitNavigator() {
        this.initButtonLinkRefForNavigationBetweenProfiles(this.contextMenuButtonIdSelector,this.placeholderForContextNavigationBetweenProfiles,this.defultDropdownSelector)
    }

    private initButtonLinkRefForNavigationBetweenProfiles(grafContextbuttonSelector: string, paramSelector: string, dropdownTraitSelector: string): void {
        const button = $(grafContextbuttonSelector);

        button.on("click", function(e) {
            var selectedTrait = $(dropdownTraitSelector).attr("title");
            $('#contextMenu a').each(function() {
                var href = $(this).attr('href');
                href = href.replace(paramSelector, selectedTrait);
                $(this).attr('href', href);
            })
        })
    }


}
