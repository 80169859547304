export class SplitRespondentProfilePostModel {

    constructor(respondentId: string, profileToSplit: string) {
        this.respondentId = respondentId;
        this.profileToSplit = profileToSplit;
    }

    private respondentId: string;
    private profileToSplit: string;
}
