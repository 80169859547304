import * as React from "react";

interface IEmblaIconProps {
    iconName: string;
    classes?: string;
    onMouseMove?: (e: React.MouseEvent<SVGSVGElement>) => void;
    onMouseOut?: (e: React.MouseEvent<SVGSVGElement>) => void;
}

export const EmblaIcon = (props: IEmblaIconProps) => {
    const additionalClasses = props.classes ? ` ${props.classes}` : "";

    return (
        <svg className={`embla-icon ${additionalClasses}`} aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
             onMouseMove={(e) => props.onMouseMove !== undefined ? props.onMouseMove(e) : null}
             onMouseOut={(e) => props.onMouseOut !== undefined ? props.onMouseOut(e) : null}>
            <use href={`/dist/icons/sprite.symbol.svg#${props.iconName}`}/>
        </svg>
    );
}
