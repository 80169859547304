export const focusPersonProfileUrl: string = "FocusPersonprofile/FocusPersonprofile";
export const focusPersonProfileListUrl: string = "FocusPersonprofile/FocusPersonprofileList";
export const focusGroupProfileUrl: string = "FocusGroupProfile/FocusGroupProfile";
export const focusGroupProfileListUrl: string = "FocusGroupProfile/FocusGroupProfileList";
export const focusJobProfileListUrl: string = "FocusJobprofile/FocusJobprofileList";
export const focusMirrorProfileListUrl: string = "FocusMirrorprofile/FocusMirrorprofileList";
export const focusDreamProfileListUrl: string = "FocusDreampositionprofile/FocusDreampositionprofileList";

export const copJobProfileUrl: string = "Jobprofil/Jobprofile";
export const copJobProfileListUrl: string = "Jobprofil/JobprofileList";
export const copJobProfileEditUrl: string = "Jobprofil/JobprofileEdit?profileId=";
export const copPersonProfileUrl: string = "Personprofil/Personprofile";
export const copPersonProfileListUrl: string = "Personprofil/PersonprofileList";
export const copMirrorProfileListUrl: string = "Mirrorprofil/MirrorprofileList";
export const copReferenceProfileListUrl: string = "Referenceprofil/ReferenceprofileList";

export const basisProfileRespondentListUrl: string = "BasisProfileRespondent/BasisProfileRespondentList";

export const eSportPersonProfileListUrl: string = "ESportPersonprofile/ESportPersonprofileList";
export const eSportMirrorProfileListUrl: string = "ESportMirrorprofile/ESportMirrorprofileList";

export const footballPersonProfileListUrl: string = "FootballPersonprofile/FootballPersonprofileList";

export const garudaLogicRespondentListUrl: string = "GarudaLogicRespondent/GarudaLogicRespondentList";

export const golfPersonProfileListUrl: string = "GolfPersonprofile/GolfPersonprofileList";
export const golfMirrorProfileListUrl: string = "GolfMirrorprofile/GolfMirrorprofileList";

export const handballPersonProfileListUrl: string = "HandballPersonprofile/HandballPersonprofileList";
export const handballMirrorProfileListUrl: string = "HandballMirrorprofile/HandballMirrorprofileList";
