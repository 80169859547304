import {GarudaApi} from "./infrastructure/garudaApi";
import {DatetimePickerModule, DatetimePickerTypeEnum, DropdownModule, NotificationModule} from "ditmer-embla";
import {Localization} from "./modules/localization-module";
import {InviteUserModule} from "./modules/invite-user-module";
import moment = require("moment");
import {inputDateFormat} from "./models/constants/dateFormat";
import {customizeSharing, deleteSharing} from "./profileSharingConstants";
import {ProfileSharingModal} from "./modules/profile-sharing-module";
import {UserRoleModel} from "./models/users/UserRoleModel";
import {UserSharingCustomization} from "./modules/user-sharing-customization-module/user-sharing-customization-module";

export class ProfileSharing {
    private readonly garudaApi: GarudaApi;

    private inviteUserModule: InviteUserModule;
    private datePicker: DatetimePickerModule;
    private profileSharingModal: ProfileSharingModal;
    private userSharingCustomization: UserSharingCustomization;

    private readonly profileIds: string[];
    private readonly multipleProfiles: boolean;
    private readonly userSelectSelector: string = "#profile-sharing-knownuser";
    private userId: string;

    private readonly sharedUsersRoleSelect: string = ".shared-users .role-select";
    private readonly emailSelector: string = ".user-email";
    private readonly newUsersContainer: string = ".new-users-container";

    private dateOpened: boolean;

    private readonly modalContainerSelector: string = "#modal-customizesharing-container";
    private readonly modalSelector: string = "#profile-sharing-modal";
    private readonly modalApplySelector: string = "#modal-btn-customize-shared-profile";
    private readonly modalTitleSelector: string = "#modal-share-profile-header";
    private readonly modalSpinnerSelector = "#share-modal-spinner";

    constructor(profileIds: string[], multipleProfiles: boolean = false) {
        this.garudaApi = new GarudaApi();
        this.profileIds = profileIds;
        this.multipleProfiles = multipleProfiles;
        this.dateOpened = false;

        this.userSharingCustomization = new UserSharingCustomization({
            profileIds: this.profileIds,
            modalContainerSelector: this.modalContainerSelector,
            sharedUsersRoleSelector: this.sharedUsersRoleSelect,
            openSharingModal: () => this.openSharingModal()
        });

        this.profileSharingModal = new ProfileSharingModal(
            async() => {
                await this.openSharingModal();
            },
            this.modalSelector,
            async() => {
                this.userSharingCustomization.saveCustomizedSharing();
            },
            this.modalApplySelector,
            this.modalTitleSelector,
            this.modalSpinnerSelector,
            this.modalContainerSelector
        );
        this.userSharingCustomization.profileSharingModal = this.profileSharingModal;
    }

    public async openSharingModal(): Promise<void> {
        this.profileSharingModal.setModalTitle(Localization.getText("Global_Sharing"));
        this.profileSharingModal.showShareModal();
        this.profileSharingModal.showSpinnner();
        const modalBodyHtml = await this.getModalBody();

        this.profileSharingModal.hideSpinner();
        $(this.modalContainerSelector).html(modalBodyHtml);

        this.initNewUserModule();
        await this.inviteUserModule.AddSavedUsers();
        this.initDatepicker();
        this.initUserRoleSelect();
        this.initUserSelect();
    }

    private async getModalBody(): Promise<string>
    {
        return this.multipleProfiles
            ? await this.garudaApi.getMultiProfileSharing(this.profileIds)
            : await this.garudaApi.getProfileSharing(this.profileIds[0]);
    }

    private initNewUserModule() {
        if (this.inviteUserModule === undefined)
        {
            this.inviteUserModule = new InviteUserModule({
                usersContainerSelector: ".new-users-container",
                usersProfileSharingUserSelector: ".profile-sharing-user",
                usersProfileSharingUserInfoSelector: ".profile-sharing-user-info",
                usersProfileSharingRoleSelector: ".profile-sharing-role-select .new-user",
                usersEmailSelector: `${this.newUsersContainer} ${this.emailSelector}`,
                usersRoleSelectSelector: ".new-users .role-select",
                userInputSelector: ".add-user-btn",
                userInputEmailSelector: "#new-user-input-email",
                userInputNameSelector: "#new-user-input-name",
                inviteUserBtnSelector: "#new-user-btn",
                profileIds: this.profileIds,
                openCustomizeModalCallback: (email: string, name: string, userRoleModels: UserRoleModel[]) => this.userSharingCustomization.openCustomizeSharing(email, name, null, true, userRoleModels)
            });
            this.userSharingCustomization.inviteUserModule = this.inviteUserModule;
        }

        this.inviteUserModule.init();
    }

    private initDatepicker() {
        this.datePicker = new DatetimePickerModule("#datepicker", {
            type: DatetimePickerTypeEnum.Date,
            onSetDate: (event, inst) => this.setTimeLimit(event.date),
            allowClear: true,
            additionalMbscCalenderOptions: {
                clearText: Localization.getText("Global_Clear")
            }
        });
    }

    private async setTimeLimit(date: string) {
        const datepickerDate = moment(this.datePicker.getValue(), inputDateFormat, true);
        let formattedDate = moment(date, inputDateFormat, true).format();

        if (!this.dateOpened || datepickerDate.format() === formattedDate) {
            this.dateOpened = true;
            return;
        }

        if (!datepickerDate.isValid()) {
            this.dateOpened = false;
            formattedDate = null;
        }

        if (this.multipleProfiles)
        {
            await this.garudaApi.setMultiProfileSharingTimeLimit(this.profileIds, formattedDate);
        }
        else
        {
            await this.garudaApi.setProfileSharingTimeLimit(this.profileIds[0], formattedDate);
        }
        NotificationModule.showSuccessSmall(Localization.getText("ProfilSharingModal_SharingUpdated"));
    }

    private initUserSelect() {
        const $this = this;

        new DropdownModule().init(this.userSelectSelector, {
            allowClear: true,
            placeholder: Localization.getText("ProfilSharingModal_Choose"),
            additionalSelect2Options: {
                language: { noResults: function() { return Localization.getText("Global_NoResult"); }
            }
        }});

        $(this.userSelectSelector).off("select2:select").on("select2:select", function (e) {
            const userId = e.params.data.id;

            if ($this.multipleProfiles)
            {
                $this.garudaApi.addMultiProfileSharing($this.profileIds, userId).then(() => {
                    $this.openSharingModal();
                });
            }
            else
            {
                $this.garudaApi.addProfileSharing($this.profileIds[0], userId).then(() => {
                    $this.openSharingModal();
                });
            }
        });
    }

    private initUserRoleSelect() {
        const $this = this;
        new DropdownModule().init(this.sharedUsersRoleSelect, {
            searchable: false
        });

        $(this.sharedUsersRoleSelect).off("select2:select").on("select2:select", function (e) {
            const roleId = e.params.data.id;
            $this.userId = $(this).data("user-id");
            const userEmail = $(this).data("user-email");
            const userName = $(this).data("user-name");

            if (roleId && roleId == customizeSharing) {
                $this.userSharingCustomization.openCustomizeSharing(userEmail, userName, $this.userId)
            }
            else if (roleId && roleId == deleteSharing) {
                $this.profileSharingModal.showSpinnner();
                $this.garudaApi.deleteProfileSharing($this.profileIds[0], $this.userId).then(() => {
                    $this.openSharingModal();
                });
            }
            else if (roleId) {
                $this.garudaApi.updateProfileRole($this.profileIds[0], $this.userId, roleId).then(() => {
                    NotificationModule.showSuccessSmall(Localization.getText("ProfilSharingModal_SharingUpdated"));
                });
            }
        });
    }


}
