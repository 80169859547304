export const LanguageDa: string = "DAN";
export const currentAttachedProfilesStorageKey: string = "attached-profiles";
export const AttachedProfileId: string = "AttachedProfileId";

export const toolGarudaLogic: string = "IQ";

export const subtoolPersonal: string = "PERSONAL";
export const subtoolMirror: string = "MIRROR";
export const subtoolMirrorFollowup: string = "MIRRORFOLLOWUP";
export const settingsConstantsAnalysisPurpose: string = "ANALYSIS_PURPOSE";
export const settingsConstantsProfileAssociation: string = "PROFILE_ASSOCIATION";
export const settingsConstants_sms_notify_user: string = "SMS_NOTIFY_USER";
export const settingConstant_SelfEvaluation: string = "SELF_EVALUATION";
export const anonymous_setting_select_keyword: string = "EAnonymizeRespondent";
