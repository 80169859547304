import * as React from "react";
import {GarudaApi} from "../../infrastructure/garudaApi";
import {TemplateListModel} from "../../models/TemplateListModel";
import {Localization} from "../../modules/localization-module";
import {RespondentModel} from "../../models/profileCrud/RespondentModel";
import {RespondentDistributionModel} from "../../models/RespondentDistributionModel";
import {SpinnerComponent} from "../ui-components/SpinnerComponent";
import {Select2Component} from "../ui-components/Select2Component";
import {SelectItemModel} from "../../models/SelectItemModel";
import useLatestRespondentCreatedHook from "../reactHooks/useLocalstorageHook";

interface IRespondentDistributionProps {
    toolId: string;
    subtoolId: string;
    respondent: RespondentModel;
    distributionModel: RespondentDistributionModel;
    onSendCopy: (sendCopy: boolean) => void;
    onCountryCodeChanged: (cc: string) => void;
    onPhoneNumberChanged: (phoneNumber: string) => void;
    onSmsBodyChanged: (smsBody: string) => void;
    onSendSmsChanged: (sendSms: boolean) => void;
    onEmailBodyChanged: (emailBody: string) => void;
    onEmailChanged: (email: string) => void;
    onSubjectChanged: (subject: string) => void;
}

interface IRespondentDistributionState {
    availableTemplates: TemplateListModel[];
    selectedTemplate: TemplateListModel;
    isLoading: boolean;
    distributionModel: RespondentDistributionModel;
}

export class RespondentDistributionComponent extends React.Component<IRespondentDistributionProps, IRespondentDistributionState> {

    private readonly garudaApi: GarudaApi;
    private readonly storageDistribution = useLatestRespondentCreatedHook<TemplateListModel>();
    private readonly SELECTED_TEMPLATE_STORAGE_KEY ="selectedTemplate";

    constructor(props: Readonly<IRespondentDistributionProps>) {
        super(props);

        this.garudaApi = new GarudaApi();

        this.state = {
            availableTemplates: [],
            selectedTemplate: null,
            isLoading: true,
            distributionModel: new RespondentDistributionModel()
        }
    }

    public async componentDidMount() {
        if (this.props.respondent.countryCode &&
            (!this.props.distributionModel.countryCode || this.props.distributionModel.countryCode.trim() === "")) {
            this.props.onCountryCodeChanged(this.props.respondent.countryCode);
        }

        if (this.props.respondent.phone &&
            (!this.props.distributionModel.phoneNumber || this.props.distributionModel.phoneNumber.trim() === "")) {
            this.props.onPhoneNumberChanged(this.props.respondent.phone);
        }

        const dist = this.props.distributionModel;

        const templateListModel = this.storageDistribution.getValue(this.SELECTED_TEMPLATE_STORAGE_KEY,this.state.selectedTemplate,(src: TemplateListModel, dist:TemplateListModel) => {
            const tempDist = dist === null ? new TemplateListModel() : dist;
            tempDist.templateId = src.templateId;
            tempDist.templateBody = src.templateBody;
            tempDist.subject = src.subject;
            tempDist.displayName = src.displayName;
            tempDist.mergeFields = src.mergeFields;
            return tempDist;
        });

        this.setState({
            distributionModel: dist,
        });

        !templateListModel ? await this.init(templateListModel) : await this.init();
    }

    public async init(selectedTemplate?: TemplateListModel) {
        this.setState({
            isLoading: true
        });

        const templates = await this.garudaApi.getTemplates(this.props.toolId, this.props.subtoolId, this.props.respondent.selectedLanguage);
        if (templates) {

            const templateIncludesTemplateId = templates.includes((x: { templateId: number; })=>x.templateId === selectedTemplate.templateId);
            const selectedItemDoesNotExist= (!selectedTemplate);
            if (selectedItemDoesNotExist || templateIncludesTemplateId) {
                selectedTemplate = templates[0];
                this.storageDistribution.removeStorage(this.SELECTED_TEMPLATE_STORAGE_KEY);
            }

            const respondent = this.props.respondent;

            const mergedMailTemplate = await this.garudaApi.getMergedTemplateId(
                respondent.profileId,
                selectedTemplate.templateId,
                respondent.selectedToolId,
                respondent.selectedSubtoolId,
                respondent.selectedLanguage,
                respondent.associatedProfileName,
                respondent.additionalGarudaLogicProfileId
            );

            selectedTemplate.templateBody = mergedMailTemplate.mailBody;
            selectedTemplate.subject = mergedMailTemplate.subject;

            this.props.onEmailBodyChanged(mergedMailTemplate.mailBody);
            this.props.onSubjectChanged(mergedMailTemplate.subject);

            let mergedSmsTemplate;
            if (selectedTemplate.smsTemplateId) {
                mergedSmsTemplate = await this.garudaApi.getMergedSMSTemplate(respondent.profileId,
                    selectedTemplate.smsTemplateId,
                    respondent.selectedToolId,
                    respondent.selectedSubtoolId,
                    respondent.selectedLanguage,
                    respondent.associatedProfileName);

                this.props.onSmsBodyChanged(mergedSmsTemplate.mailBody);
            }
        }

        this.setState({
            availableTemplates: templates,
            selectedTemplate: selectedTemplate,
            isLoading: false
        });
    }

    private smsSettingChanged(e: React.MouseEvent<HTMLInputElement, MouseEvent>) {
        const value = e.target as HTMLInputElement;
        this.props.onSendSmsChanged(value.checked);
    }

    private sendCopySettingChanged(e: React.MouseEvent<HTMLInputElement, MouseEvent>) {
        const value = e.target as HTMLInputElement;
        this.props.onSendCopy(value.checked);
    }

    private onEmailBodyChange(event: React.ChangeEvent<HTMLTextAreaElement>) {
        this.props.onEmailBodyChanged(event.currentTarget.value);
    }

    private onCountryCodeChange(value: string) {
        this.props.onCountryCodeChanged(value);
    }

    private onPhoneNumberChanged(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.onPhoneNumberChanged(event.currentTarget.value);
    }

    private onSmsBodyChanged(event: React.ChangeEvent<HTMLTextAreaElement>) {
        this.props.onSmsBodyChanged(event.currentTarget.value);
    }

    private onEmailChanged(value: string) {
        this.props.onEmailChanged(value);
    }

    private onSubjectChanged(value: string) {
        this.props.onSubjectChanged(value);
    }

    private async getTemplateId(templateId: number) {
        const selectedTemplate: TemplateListModel = this.state.availableTemplates.find(template => template.templateId === templateId);
        this.storageDistribution.setValue(this.SELECTED_TEMPLATE_STORAGE_KEY, selectedTemplate);
        await this.init(selectedTemplate);
    }

    private renderTemplateSelection() {
        const $this = this;
        const templateOptions = this.state.availableTemplates.map((templateModel: TemplateListModel) => {
            let selectItem = new SelectItemModel();
            selectItem.label = templateModel.displayName;
            selectItem.value = templateModel.templateId.toString();
            selectItem.selected = $this.state.selectedTemplate.templateId === templateModel.templateId;
            return selectItem;
        });

        return (<div className={"form-group"}>
            <label htmlFor={"template-selector"}
                   className={"bold"}>{Localization.getText("PersonalProfile_Distribution_Template")}</label>
            <Select2Component multiple={false}
                              options={templateOptions}
                              select2Id="template-selector"
                              itemSelected={(value) => this.getTemplateId(parseInt(value))}
                              itemUnselected={() => null}
                              additionalSelect2Options={{minimumResultsForSearch: -1}}
            />
        </div>);
    }

    private renderTemplateEmail() {
        return (
            <div className={"form-group"}>
                <label htmlFor={"template-selector"} className={"bold"}>
                    {Localization.getText("GENERAL_EMAIL")}
                    <span className="required-field">∗</span>
                </label>
                <input type="text" className="form-control" id="email" defaultValue={this.state.distributionModel.email}
                       onChange={(e) => this.onEmailChanged(e.target.value)}
                       placeholder={Localization.getText("GENERAL_EMAIL")}/>
            </div>
        );
    }

    private renderTemplateSubject() {
        return (
            <div className={"form-group"}>
                <label htmlFor={"template-selector"} className={"bold"}>
                    {Localization.getText("PersonalProfile_Respondent_Subject")}
                    <span className="required-field">∗</span>
                </label>
                <input type="text" className="form-control" id="subject"
                       defaultValue={this.state.selectedTemplate.subject}
                       onChange={(e) => this.onSubjectChanged(e.target.value)}
                       placeholder={Localization.getText("PersonalProfile_Respondent_Subject_Placeholder")}/>
            </div>
        );
    }

    private renderTemplateBody() {
        return (
            <div className={"form-group"}>
                <label htmlFor="template-body"
                       className={"bold"}>{Localization.getText("PersonalProfile_Distribution_MessageForRespondent")}</label>
                <textarea id={"template-body"} className={"form-control"} value={this.state.distributionModel.emailBody}
                          onChange={(e) => this.onEmailBodyChange(e)}/>
            </div>);
    }

    private renderSmsBody() {

        let messageFields = (<>&nbsp;</>);

        if (this.state.distributionModel.sendSms) {
            messageFields = (<div>
                <div className={"sms-container-form row"}>
                    <div className="col-lg-2 col-md-2 col-2">
                        <div className="form-group">
                            <label
                                htmlFor={"CountryCode"}>{Localization.getText("PersonalProfile_Respondent_CountryCode")}</label>
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <div className="input-group-text">+</div>
                                </div>
                                <input name="CountryCode" type="text" className="form-control" id="iconLeftInput"
                                       onChange={(e) => parseInt(e.target.value) ? this.onCountryCodeChange(e.target.value) : this.onCountryCodeChange("")}
                                       defaultValue={this.state.distributionModel.countryCode}
                                       value={this.state.distributionModel.countryCode}
                                       placeholder={Localization.getText("PersonalProfile_Respondent_CountryCode_Placeholder")}/>
                            </div>
                        </div>
                    </div>

                    <div className={"col-lg-2 col-md-6 col-6"}>
                        <div className="form-group">
                            <label
                                htmlFor="phoneNumber">{Localization.getText("PersonalProfile_Respondent_Phone")}</label>
                            <input type="text" className="form-control" id="phoneNumber"
                                   defaultValue={this.state.distributionModel.phoneNumber}
                                   onChange={(e) => this.onPhoneNumberChanged(e)}
                                   placeholder={Localization.getText("PersonalProfile_Respondent_Phone_Placeholder")}/>
                        </div>
                    </div>
                </div>

                <div className={"form-group"}>
                    <label htmlFor={"smsBody"}>
                        {Localization.getText("PersonalProfile_Distribution_MessageBody")}
                        <div className="subtle"> {Localization.getText("PersonalProfile_Distribution_SMSLength")}</div>
                    </label>
                    <textarea id={"smsBody"} value={this.state.distributionModel.smsBody} className={"form-control"}
                              maxLength={160} onChange={(e) => this.onSmsBodyChanged(e)}/>
                </div>
            </div>);
        }

        return (<div>
            <div className="form-group margin-top-l">
                <input type={"checkbox"} name={"send-sms"} id={"send-copy"}
                       defaultChecked={this.state.distributionModel.sendCopy}
                       onClick={(e) => this.sendCopySettingChanged(e)}/>
                <label className="checkbox-label"
                       htmlFor="send-copy">{Localization.getText("PersonalProfile_Distribution_Send_Copy")}</label>
            </div>
            <div className="form-group">
                <input type={"checkbox"} name={"send-sms"} id={"send-sms"}
                       defaultChecked={this.state.distributionModel.sendSms}
                       onClick={(e) => this.smsSettingChanged(e)}/>
                <label className="checkbox-label"
                       htmlFor="send-sms">{Localization.getText("PersonalProfile_Distribution_NotifyWhenEmailSent")}</label>
                {messageFields}
            </div>
        </div>);
    }

    public render() {

        if (this.state.isLoading) {
            return (<SpinnerComponent/>);
        }

        return (<div>
            {
                this.state.availableTemplates &&
                this.renderTemplateSelection()
            }
            {
                this.state.availableTemplates &&
                this.renderTemplateEmail()
            }
            {
                this.state.availableTemplates &&
                this.renderTemplateSubject()
            }
            {
                this.state.selectedTemplate &&
                this.renderTemplateBody()
            }
            {this.renderSmsBody()}
        </div>);
    }
}
