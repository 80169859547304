import * as React from "react";

export interface ISpinnerProps {
    color?: "white" | "primary";
    size?: "large";
    additionalClasses?: string;
    small?: boolean;
}

export const SpinnerComponent = (props: ISpinnerProps) => {

    let color = props.color;
    if (color === undefined) {
        color = "primary";
    }

    let classes = "spinner";
    if (props.additionalClasses !== undefined && props.additionalClasses !== null && props.additionalClasses.length > 0) {
        classes += ` ${props.additionalClasses}`;
    }
    if (props.size) {
        classes += ` ${props.size}`;
    }
    if (props.small){
        classes += ` small`;
    }

    return (
        <div className={classes}>
            <div className={"spinner-" + color}/>
        </div>
    );
};
