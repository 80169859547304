export class Spinner {
    private readonly $spinner: JQuery<HTMLElement>;
    private readonly $content: JQuery<HTMLElement>;

    constructor(selector: string, contentSelector?: string) {
        this.$spinner = $(selector);
        this.$content = $(contentSelector);
    }

    public showSpinner(): void {
        if (this.$content) {
            this.$content.hide();
        }

        this.$spinner.show();
    }

    public hideSpinner(): void {
        if (this.$content) {
            this.$content.show();
        }

        this.$spinner.hide();
    }
}
