import * as React from "react";
import {GarudaApi} from "../../../infrastructure/garudaApi";
import {RespondentModel} from "../../../models/profileCrud/RespondentModel";
import {ToolCrudModel} from "../../../models/profileCrud/ToolCrudModel";
import {KeyValueSettingModel} from "../../../models/settings/KeyValueSettingModel";

interface IProfileSpecialSettingComponentProps {
    respondent: RespondentModel;
    toolData: ToolCrudModel;
    setSpecialSetting: (key: string, value: string) => void;
}

export class ProfileSpecialSettingComponent extends React.Component<IProfileSpecialSettingComponentProps> {

    private garudaApi: GarudaApi;

    constructor(props: IProfileSpecialSettingComponentProps) {
        super(props);

        this.garudaApi = new GarudaApi();
    }

    private renderSpecialSettings(): JSX.Element[] {
        const list: JSX.Element[] = [];

        const specialSettings: KeyValueSettingModel[] = this.selectSpecialSettings();

        if (specialSettings?.length > 0) {
            specialSettings.map((model: KeyValueSettingModel, index) => {

                const selectedValue = this.props.respondent
                    .specialSettingValues.find(sv => sv.key === model.key)?.value;

                list.push(
                    <div className="col-sm-6" key={`special-setting-key-${index}`}>
                        <div className="form-group">
                            <label className="bold" htmlFor={model.key}>{model.label}</label>
                            <input name={model.key}
                                   className="form-control"
                                   onChange={(e) => this.props.setSpecialSetting(model.key, e.target.value)}
                                   defaultValue={selectedValue}
                                   placeholder={model.label}
                                   maxLength={45}
                                   key={index} />
                        </div>
                    </div>
                );
            });
        }

        return list;
    }

    private selectSpecialSettings() {
        let specialSettings: KeyValueSettingModel[];
        if (this.props.respondent.selectedToolId && this.props.respondent.selectedSubtoolId) {
            const subToolData = this.props.toolData.SelectedSubTool(this.props.respondent.selectedToolId, this.props.respondent.selectedSubtoolId);

            if (subToolData) {
                specialSettings = subToolData.specialSetting as KeyValueSettingModel[];
            }
        }
        return specialSettings;
    }

    render() {
        return (
            <>
                {this.renderSpecialSettings()}
            </>);
    }
}
