import * as React from "react";
import { TagItem } from "./TagItem";
import { TagsModel } from "../../models/tags/TagsModel";

interface TagSharingComponentProps {
    respondentTags: boolean;
    tagModels: TagsModel[];
    removeTags: (tag: string[]) => void;
    addTags: (tag: string[]) => void;
}

export class TagSharingComponent extends React.Component<TagSharingComponentProps> {

    constructor(props: Readonly<TagSharingComponentProps>) {
        super(props);
    }

    public sharedTag(tag: string): boolean {
        let shared = true;

        this.props.tagModels.forEach(tagModel => {
            if (!tagModel.tags.find(t => t === tag)) {
                shared = false;
                return;
            }
        });

        return shared;
    }

    private renderTags(): JSX.Element[] {
        const elements: JSX.Element[] = [];

        if (this.props.tagModels.length > 0) {
            this.props.tagModels.selectMany(tm => tm.tags).distinct().map((tag) => {

                const sharedClass = this.sharedTag(tag) ? "" : "not-shared-tag";
                const respondentClass = this.props.respondentTags ? "respondent-tag" : "profile-tag";
                elements.push(
                    <TagItem className={`tag tag-sharing ${sharedClass} ${respondentClass}`}
                             key={tag}
                             tag={tag}
                             menu={!this.sharedTag(tag)}
                             removeTag={() => this.props.removeTags([tag])}
                             addTag={() => this.props.addTags([tag])}/>
                )
            });
        }

        return elements;
    }

    render() {
        return (
            <>
                {this.renderTags()}
            </>);
    }
}
