export enum ToolEnum {
    All = 0,
    KompetenceProfile = 1,
    FocusProfile = 2,
    BasisProfile = 3,
    JobMatchProfile = 4,
    JobSpotsProfile = 5,
    GarudaLogicProfile = 6,
    ConsultantProfile = 7,
    ESportProfile = 8,
    FootballProfile = 9,
    GolfProfile = 10,
    GarudaProfile = 11,
    PreferenceProfile = 12,
    SportProfile = 13,
    HandballProfile = 14,
    LeadersMentalScorecard = 15
}

export enum ToolConstants {
    All = "all",
    BasisProfileId = "BASIS",
    KompetenceProfileId = "COMPRO_SCALE.NET",
    FocusProfileId = "FP.NET",
    GarudaLogicProfileId = "IQ",
    JobMatchProfileId = "JOBMATCH",
    JobSpotsProfileId = "JOBSPOTS",
    ConsultantProfileId = "CONSULTSERVICES",

    ESportProfileId = "FPESPCSGO",
    FootballProfileId = "FPFOOTBALL",
    GolfProfileId = "FPGOLF",
    HandballProfileId = "HBP",

    GarudaProfileId = "GP.NET",
    PreferenceProfileId = "GPP",
    SportProfileId = "GPPSPORT",
    LeadersMentalScorecardId = "MENTAL SCORECARD",
}

export class ToolEnumToConstantsConverter {

    public static Convert(constants: string) : ToolEnum {

        switch (constants) {
            case "all": return ToolEnum.All
            case "BASIS": return ToolEnum.BasisProfile
            case "COMPRO_SCALE.NET": return ToolEnum.KompetenceProfile
            case "FP.NET": return ToolEnum.FocusProfile
            case "IQ": return ToolEnum.GarudaLogicProfile
            case "JOBMATCH": return ToolEnum.JobMatchProfile
            case "JOBSPOTS": return ToolEnum.JobSpotsProfile
            case "CONSULTSERVICES": return ToolEnum.ConsultantProfile

            case "FPESPCSGO": return ToolEnum.ESportProfile
            case "FPFOOTBALL": return ToolEnum.FootballProfile
            case "FPGOLF": return ToolEnum.GolfProfile
            case "HBP": return ToolEnum.HandballProfile

            case "GP.NET": return ToolEnum.GarudaProfile
            case "GPP": return ToolEnum.PreferenceProfile
            case "GPPSPORT": return ToolEnum.SportProfile
            case "MENTAL SCORECARD": return ToolEnum.LeadersMentalScorecard
        }
    }
}
