import { GarudaApi } from "../../infrastructure/garudaApi";

declare global {
    interface Window {
        arrowClick: (event: any, ENormal: string, profileId: string, trait: number, addScore: number) => void;
    }
}

interface ISvgClickProps {
    showGraphsCallback: () => void;
}

export class SvgClickModule {

    private readonly svgProps: ISvgClickProps;
    private readonly garudaApi: GarudaApi;

    constructor(svgProps: ISvgClickProps) {
        this.svgProps = svgProps;
        this.garudaApi = new GarudaApi();
    }

    public init() {
        this.arrowClick = this.arrowClick.bind(this);
        window.arrowClick = this.arrowClick;
    }

    private async arrowClick(event: any, ENormal: string, profileId: string, trait: number, addScore: number) {
        await this.garudaApi.patchSvgClicked(profileId, ENormal, trait, addScore);
        this.svgProps.showGraphsCallback();
    }
}
