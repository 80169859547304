import * as React from "react";

export const TagIcon = () => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="16" cy="16" r="16" fill="#233F6B" />
            <mask id="mask0_403_9311" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="0" y="0" width="32" height="32">
                <circle cx="16" cy="16" r="16" fill="white" />
            </mask>
            <g mask="url(#mask0_403_9311)">
            </g>
            <path d="M14.6601 26.9163L22.99 13.2585L21.4 7.3999L15.0815 8.43507L6.75156 22.0929L14.6601 26.9163Z" fill="#03A9F4" />
            <path d="M21.6605 13.5316L15.4468 9.74182L8.08062 21.8195L14.2944 25.6092L21.6605 13.5316Z" fill="#67DAFF" />
            <path d="M19.7 11.4C20.418 11.4 21 10.818 21 10.1C21 9.38208 20.418 8.80005 19.7 8.80005C18.982 8.80005 18.4 9.38208 18.4 10.1C18.4 10.818 18.982 11.4 19.7 11.4Z" fill="#233F6B" />
            <path fillRule="evenodd" clipRule="evenodd" d="M24.1458 6.98208C24.307 6.88232 24.5185 6.93194 24.6185 7.09298C24.7187 7.25437 24.669 7.46648 24.5074 7.56646L19.8543 10.4462C19.6931 10.546 19.4815 10.4964 19.3815 10.3353C19.2813 10.1739 19.331 9.96184 19.4926 9.86186L24.1458 6.98208Z" fill="white" />
        </svg>
    )
}